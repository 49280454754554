import React from 'react';

function ByElections() {
  return (
    <div>
      <h1>By-Elections</h1>
      <br />
      <h3 id="RBCbyElections">Regional Bahá’í Council By-elections</h3>
      <ul className="plain-list">
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/20220930-NSA-to-all-LSAs-Reporting-by-elections.pdf">
            30 Sep 2022 Letter from the NSA to all LSAs about reporting by-elections
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Attachment-1-2022-By-elections-for-RBC-Balloting-Process.pdf">
            Balloting process for RBC by-elections
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Attachment-2-2022-By-election-form.pdf">
            By-election form
          </a>
        </li>
      </ul>
      <h3 id="RBCbyElections">Local Spiritual Assembly By-elections</h3>
      <ul className="plain-list">
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2023/By-Election-Report-2023.pdf">
            Local Spiritual Assembly By-Election Report Form 2023 (PDF)
          </a>
        </li>
      </ul>
    </div>
  );
}

export default ByElections;
