import React, { useState, FC } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import type SearchResultModel from './Utilities/SearchResultModel';
import type { History } from 'history';
import type ContentResolver from '../Content/ContentResolver';

const SearchLink: FC<{
  details: SearchResultModel;
  searchShowsScore: boolean;
  history: History;
  context: {
    dateOptions: Intl.DateTimeFormatOptions;
    contentResolver: ContentResolver;
  };
}> = ({ details, searchShowsScore, history, context: { dateOptions, contentResolver } }) => {
  const [error, setError] = useState();
  if (error) {
    throw error;
  }

  let linkDisplayTitle = '';
  let lastUpdated = '';
  let linkdisplay = <></>;
  let categoryType = '';

  if (details) {
    categoryType = details.categorytype;

    if (categoryType === 'letter' || categoryType === 'feast_message' || categoryType === 'cover_letter') {
      const dateAppend = details.datepublished
        ? ` - ${new Date(details.datepublished).toLocaleDateString('en-US', dateOptions)}`
        : '';
      linkDisplayTitle = `${details.title}${dateAppend}`;
    } else {
      linkDisplayTitle = details.title;
    }
    if (categoryType !== 'writing') {
      lastUpdated = `Last Updated: ${new Date(details.datepublished).toLocaleDateString('en-US', dateOptions)}`;
    }
    if (categoryType.toUpperCase() === 'GLSA') {
      categoryType = 'GLSA';
    }

    linkdisplay = (
      <h4 className="pointer-finger" onClick={navigateToPage}>
        {linkDisplayTitle}
      </h4>
    );
  }
  function navigateToPage() {
    contentResolver.ResolveUrl(details.kenticoprojectid, details.systemcodename, details.language).then(
      (x) => {
        history.push(x);
      },
      (err) => {
        setError(err);
      }
    );
  }

  return (
    <div className="top-margin">
      {linkdisplay}
      <p className="capitalize green-text" data-cy="categorytypeuppercase">{`${categoryType.replace('_', ' ')}`}</p>
      {lastUpdated ? <p className="faded-text small-text end">{lastUpdated}</p> : null}
      <p>{details.summary}</p>
      {searchShowsScore ? <p>Score: {details['@search.score']}</p> : null}
    </div>
  );
};

export default withContext(SearchLink);
