import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import { ReplaySubject, of, forkJoin, Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaxonomyTerms, Elements, Link, ContentItem } from '@kentico/kontent-delivery';

const baseUrl = '/glsa';
const titleForBaseUrl = 'GLSA';

const getApplicableChapterTaxonomy = (chaptersElement: Elements.TaxonomyElement) =>
  chaptersElement.value[chaptersElement.value.length - 1];

const flattenTaxonomyTerms = (
  chapterTerms: TaxonomyTerms[],
  glsaItemDates: ContentItem[]
): { [key: string]: { url: string; title: string } } => {
  const glsaItemDateValues = glsaItemDates.reduce((accumulator: { [key: string]: string }, glsaItemDate) => {
    const chaptersElement: Elements.TaxonomyElement = glsaItemDate.chapters;
    const applicableTaxonomy = getApplicableChapterTaxonomy(chaptersElement);
    const applicableTaxonomyCodename = applicableTaxonomy?.codename;
    if (!applicableTaxonomyCodename) {
      console.error(
        `USBNC Community Site: Failed to match the following glsaItemDate (${glsaItemDate?.system?.codename}) to a chapters taxonomy element`,
        glsaItemDate?.system
      );
      return accumulator;
    }

    const glsaItemDateElement: Elements.DateTimeElement = glsaItemDate.base__revised_date;
    const glsaItemDateElementIsoFormatted = glsaItemDateElement?.value?.toISOString() ?? 'Unknown';

    accumulator[applicableTaxonomyCodename] = glsaItemDateElementIsoFormatted;
    return accumulator;
  }, {});

  return chapterTerms.reduce(
    (
      accumulator: {
        urlsByCodename: {
          [key: string]: {
            url: string;
            title: string;
            prevUrl: string;
            prevTitle: string;
            nextUrl: string;
            nextTitle: string;
            revisedDate: string;
          };
        };
        prevUrl: string;
        prevTitle: string;
        prevCodename: string;
      },
      currentChapter
    ) => {
      const chapterUrl = `${baseUrl}/${currentChapter.codename}`;
      if (accumulator.prevCodename.length > 0) {
        var prevUrlByCodenameItem = accumulator.urlsByCodename[accumulator.prevCodename];
        prevUrlByCodenameItem.nextUrl = chapterUrl;
        prevUrlByCodenameItem.nextTitle = currentChapter.name;
      }
      accumulator.urlsByCodename[currentChapter.codename] = {
        url: chapterUrl,
        title: currentChapter.name,
        prevUrl: accumulator.prevUrl,
        prevTitle: accumulator.prevTitle,
        nextUrl: baseUrl,
        nextTitle: titleForBaseUrl,
        revisedDate: 'Unknown',
      };
      accumulator.prevUrl = chapterUrl;
      accumulator.prevTitle = currentChapter.name;
      accumulator.prevCodename = currentChapter.codename;
      let mostRecentRevisedDate: string | undefined;
      const accumulatedSections = currentChapter.terms.reduce((accumulatedSections, currentSection) => {
        const sectionUrl = `${chapterUrl}/${currentSection.codename}`;
        if (accumulatedSections.prevCodename.length > 0) {
          accumulatedSections.urlsByCodename[accumulatedSections.prevCodename].nextUrl = sectionUrl;
          accumulatedSections.urlsByCodename[accumulatedSections.prevCodename].nextTitle = currentSection.name;
        }

        const matchingSectionGlsaItemDateElementIsoFormatted = glsaItemDateValues[currentSection.codename];
        if (mostRecentRevisedDate) {
          if (mostRecentRevisedDate < matchingSectionGlsaItemDateElementIsoFormatted) {
            mostRecentRevisedDate = matchingSectionGlsaItemDateElementIsoFormatted;
          }
        } else {
          mostRecentRevisedDate = matchingSectionGlsaItemDateElementIsoFormatted;
        }

        accumulatedSections.urlsByCodename[currentSection.codename] = {
          url: sectionUrl,
          title: currentSection.name,
          prevUrl: accumulator.prevUrl,
          prevTitle: accumulator.prevTitle,
          nextUrl: baseUrl,
          nextTitle: titleForBaseUrl,
          revisedDate: matchingSectionGlsaItemDateElementIsoFormatted,
        };
        accumulatedSections.prevUrl = sectionUrl;
        accumulatedSections.prevTitle = currentSection.name;
        accumulatedSections.prevCodename = currentSection.codename;
        const accumulatedSubSections = currentSection.terms.reduce((accumulatedSubSections, currentSubSection) => {
          const subSectionUrl = `${sectionUrl}/${currentSubSection.codename}`;
          if (accumulatedSubSections.prevCodename.length > 0) {
            accumulatedSubSections.urlsByCodename[accumulatedSubSections.prevCodename].nextUrl = subSectionUrl;
            accumulatedSubSections.urlsByCodename[accumulatedSubSections.prevCodename].nextTitle = currentSubSection.name;
          }

          const matchingSubSectionGlsaItemDateElementIsoFormatted = glsaItemDateValues[currentSubSection.codename];

          if (mostRecentRevisedDate) {
            if (mostRecentRevisedDate < matchingSubSectionGlsaItemDateElementIsoFormatted) {
              mostRecentRevisedDate = matchingSubSectionGlsaItemDateElementIsoFormatted;
            }
          } else {
            mostRecentRevisedDate = matchingSubSectionGlsaItemDateElementIsoFormatted;
          }

          accumulatedSubSections.urlsByCodename[currentSubSection.codename] = {
            url: subSectionUrl,
            title: currentSubSection.name,
            prevUrl: accumulator.prevUrl,
            prevTitle: accumulator.prevTitle,
            nextUrl: baseUrl,
            nextTitle: titleForBaseUrl,
            revisedDate: matchingSubSectionGlsaItemDateElementIsoFormatted,
          };
          accumulatedSubSections.prevUrl = subSectionUrl;
          accumulatedSubSections.prevTitle = currentSubSection.name;
          accumulatedSubSections.prevCodename = currentSubSection.codename;
          return accumulatedSubSections;
        }, accumulatedSections);
        return accumulatedSubSections;
      }, accumulator);
      accumulator.urlsByCodename[currentChapter.codename].revisedDate = mostRecentRevisedDate ?? 'Unknown';
      return accumulatedSections;
    },
    {
      urlsByCodename: {},
      prevUrl: baseUrl,
      prevTitle: titleForBaseUrl,
      prevCodename: '',
    }
  ).urlsByCodename;
};

export default class GlsaProxy {
  glsaContent: ContentProviderEndpoint;
  taxonomySubject = new ReplaySubject<{
    chapterTerms: TaxonomyTerms[];
    urlsByCodename: { [key: string]: { url: string; title: string } };
  }>(1);
  taxonomyLoaded = false;

  constructor(glsaContent: ContentProviderEndpoint) {
    this.glsaContent = glsaContent;
  }

  getLinkContentItems(links: Link[]): Observable<ContentItem[]> {
    const linksToSections = links.filter((x) => x.type === 'section');
    const linksToSectionsCodenames = linksToSections.map((x) => x.codename);
    const linksToSubSections = links.filter((x) => x.type === 'subsection');
    const linksToSubSectionsCodenames = linksToSubSections.map((x) => x.codename);

    const linksToSectionsObservable =
      linksToSections.length > 0
        ? this.glsaContent
            .items()
            .type('section')
            .inFilter('system.codename', linksToSectionsCodenames)
            .elementsParameter(['chapters'])
            .toObservable()
            .pipe(map((x) => x.items))
        : of([]);

    const linksToSubSectionsObservable =
      linksToSubSections.length > 0
        ? this.glsaContent
            .items()
            .type('subsection')
            .inFilter('system.codename', linksToSubSectionsCodenames)
            .elementsParameter(['chapters'])
            .toObservable()
            .pipe(map((x) => x.items))
        : of([]);

    const allLinks = forkJoin({
      sections: linksToSectionsObservable,
      subSections: linksToSubSectionsObservable,
    });
    const allLinksMerged = allLinks.pipe(
      map(({ sections, subSections }) => {
        const bothSectionsAndSubSections = sections.concat(subSections);
        return bothSectionsAndSubSections;
      })
    );

    return allLinksMerged;
  }

  getChapters() {
    if (!this.taxonomyLoaded) {
      this.taxonomyLoaded = true;

      const allGlsaRevisedDatesObservable = this.glsaContent
        .items()
        .types(['section', 'subsection'])
        .elementsParameter(['base__revised_date', 'chapters'])
        .toObservable();

      const chaptersTaxonomyObservable = this.glsaContent.taxonomy('chapters').toObservable();

      const datesAndChaptersObservable = forkJoin({
        dates: allGlsaRevisedDatesObservable,
        chapters: chaptersTaxonomyObservable,
      });

      datesAndChaptersObservable
        .pipe(
          map((x) => {
            const { dates, chapters } = x;
            const glsaItemDates = dates.items;
            const chapterTerms = chapters.taxonomy.terms;
            const urlsByCodename = flattenTaxonomyTerms(chapterTerms, glsaItemDates);
            return { chapterTerms, urlsByCodename };
          })
        )
        .subscribe(this.taxonomySubject);
    }
    return this.taxonomySubject.asObservable();
  }

  buildUrlFromContentItemCodename(contentItemCodename: string, lang: string): Observable<string> {
    if (lang !== 'en-US') {
      return throwError(
        new Error(
          `Not Implemented: Language ${lang} not implemented. Trying to resolve contentItemCodename "${contentItemCodename}" in GLSA project.`
        )
      );
    }

    const contentItemObservable = this.glsaContent
      .item(contentItemCodename)
      .elementsParameter(['chapters', 'url_slug', 'faq_taxonomy'])
      .toObservable();

    const urlObservable = contentItemObservable.pipe(
      switchMap((x) => {
        const contentItem = x.item;

        if (contentItem?.system?.type === 'faq') {
          const faqElement: Elements.TaxonomyElement = contentItem.faq_taxonomy;
          const urlSlugElement: Elements.UrlSlugElement = contentItem.url_slug;
          return of(`/faq/${faqElement.value[0].codename}/${urlSlugElement.value}`);
        }

        if (contentItem?.system?.type === 'section' || contentItem?.system?.type === 'subsection') {
          return this.getChapters().pipe(
            map((taxonomyStuff) => {
              const chaptersElement: Elements.TaxonomyElement = contentItem.chapters;
              const contentItemChapterTaxonomy = getApplicableChapterTaxonomy(chaptersElement);
              const url = taxonomyStuff.urlsByCodename[contentItemChapterTaxonomy.codename].url;
              return url;
            })
          );
        }

        return throwError(
          new Error(
            `Not Implemented: The contentItem.system.type "${contentItem?.system?.type}" is not implemented. Trying to resolve contentItemCodename "${contentItemCodename}" in GLSA project.`
          )
        );
      })
    );

    return urlObservable;
  }
}
