import React, {
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
  ChangeEvent,
  FormEvent,
  Dispatch,
  HTMLAttributes,
} from 'react';
import { TextField } from '@material-ui/core';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { MenuItem, InputLabel, FormControl, Select, CircularProgress } from '@material-ui/core';
import { zipcodeFormat } from '../../Utilities/GeneralRegEx';
import useForm from '../../Utilities/useForm';
import type { FormSchemaModelType, ValidationStateSchemaType } from '../../Utilities/useFormTypes';
import type IPhysicalAddressProxy from '../Utilities/IPhysicalAddressProxy';
import type PhysicalAddressChangeRequestModel from '../Utilities/PhysicalAddressChangeRequestModel';
import type PhysicalAddressChangeResponseModel from '../Utilities/PhysicalAddressChangeResponseModel';
import type IMyProfileProxy from '../Utilities/IMyProfileProxy';
import { of, combineLatest } from 'rxjs';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';

type FormFields = {
  inCareOf: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipcode: string;
};

const validationStateSchema: ValidationStateSchemaType<FormFields> = {
  inCareOf: {
    required: false,
    validator: null,
  },
  line1: {
    required: true,
    validator: null,
  },
  line2: {
    required: false,
    validator: null,
  },
  city: {
    required: true,
    validator: null,
  },
  state: {
    required: true,
    validator: null,
  },
  zipcode: {
    required: true,
    validator: {
      regEx: zipcodeFormat,
      error: 'Invalid zip code format.',
    },
  },
};

const buildInitialState: () => FormSchemaModelType<FormFields> = () => {
  return {
    inCareOf: {
      value: '',
      error: '',
    },
    line1: {
      value: '',
      error: '',
    },
    line2: {
      value: '',
      error: '',
    },
    city: {
      value: '',
      error: '',
    },
    state: {
      value: '',
      error: '',
    },
    zipcode: {
      value: '',
      error: '',
    },
  };
};

export type PropsContext = {
  physicalAddressProxy: IPhysicalAddressProxy;
  myProfileProxy: IMyProfileProxy;
};

export type Props = {
  goToNextPage: () => void;
  newAddress: undefined | PhysicalAddressChangeResponseModel;
  setNewAddress: (newAddress: undefined | PhysicalAddressChangeResponseModel) => void;
  requestModel: PhysicalAddressChangeRequestModel;
  setRequestModel: React.Dispatch<React.SetStateAction<PhysicalAddressChangeRequestModel>>;
  context: PropsContext;
};

const EditAddressPage: FunctionComponent<Props> = ({
  goToNextPage,
  setNewAddress,
  requestModel,
  setRequestModel,
  context: { physicalAddressProxy, myProfileProxy },
}) => {
  const [stateOptions, setStateOptions] = useState<{ key: string; value: string }[]>([]);
  const [messageToUser, setMessageToUser] = useState<string | undefined>();
  const [showErrorMessageToUser, setShowErrorMessageToUser] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(true);

  const submitCallback = useCallback(
    (state: FormSchemaModelType<FormFields>, processErrors: (serializableErrorFromApi: object) => string) => {
      setShowCircularProgress(true);
      const zipcodeFirst5 = state.zipcode.value.slice(0, 5);
      const newPhysicalAddress: PhysicalAddressChangeRequestModel = {
        inCareOf: state.inCareOf.value,
        line1: state.line1.value,
        line2: state.line2.value,
        city: state.city.value,
        state: state.state.value,
        zipcode: zipcodeFirst5,
        toScrub: true,
        validateOnly: true,
        selectedHouseholdMemberIdns: [],
      };
      setRequestModel(newPhysicalAddress);

      physicalAddressProxy.set(newPhysicalAddress).subscribe({
        next: (responseModel) => {
          setNewAddress(responseModel);
          goToNextPage();
        },
        error: (err) => {
          if (err.response?.status === 400) {
            const unprocessedErrors = processErrors(err.response.data);
            if (unprocessedErrors === 'The field "Address" has the following error "Address cannot be verified.". ') {
              const responseModel: PhysicalAddressChangeResponseModel = {
                inCareOf: state.inCareOf.value,
                line1: state.line1.value,
                line2: state.line2.value,
                city: state.city.value,
                state: state.state.value,
                zipcode: state.zipcode.value,
                newBlc: '',
                newLocalityName: '',
                newClusterCode: '',
                newClusterName: '',
                mailingNotChangingForHouseholdMemberIdns: [],
              };
              setNewAddress(responseModel);
              goToNextPage();
              return;
            }

            if (unprocessedErrors) {
              setMessageToUser(unprocessedErrors);
            }
            setShowCircularProgress(false);
            return;
          }

          setShowCircularProgress(false);
          setShowErrorMessageToUser(true);
        },
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [physicalAddressProxy]
  );

  const {
    setState,
    state,
    handleValidationOnChange,
    handleOnSubmit,
  }: {
    setState: Dispatch<FormSchemaModelType<FormFields>>;
    state: FormSchemaModelType<FormFields>;
    handleValidationOnChange: (e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
    handleOnSubmit: (e: FormEvent<HTMLInputElement>) => void;
  } = useForm(buildInitialState(), validationStateSchema, submitCallback);

  useEffect(() => {
    const myProfileProxyGetObservable = myProfileProxy.get();
    const physicalAddressProxyGetObservable = requestModel.line1.length > 0 ? of(requestModel) : physicalAddressProxy.get();

    const combinedObservables = combineLatest([myProfileProxyGetObservable, physicalAddressProxyGetObservable]);

    const subscription = combinedObservables.subscribe({
      next: (combinedResponses) => {
        const myProfileResponse = combinedResponses[0];
        const physicalAddressResponse = combinedResponses[1];

        setStateOptions(myProfileResponse.stateOptions);

        setState({
          inCareOf: {
            value: physicalAddressResponse.inCareOf,
            error: '',
          },
          line1: {
            value: physicalAddressResponse.line1,
            error: '',
          },
          line2: {
            value: physicalAddressResponse.line2,
            error: '',
          },
          city: {
            value: physicalAddressResponse.city,
            error: '',
          },
          state: {
            value: physicalAddressResponse.state,
            error: '',
          },
          zipcode: {
            value: physicalAddressResponse.zipcode,
            error: '',
          },
        });
        setShowCircularProgress(false);
      },
      error: () => setShowErrorMessageToUser(true),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, physicalAddressProxy, requestModel]);

  if (showErrorMessageToUser) {
    return <ErrorMessageToUser />;
  }

  return (
    <>
      <div className="profile-box">
        {showCircularProgress ? <CircularProgress data-cy="circular-progress" className="loading-animation" size={40} /> : null}
        {messageToUser ? (
          <p data-cy="unsuccess-message" className="error-message wider">
            Please check the highlighted field(s) and resolve the error(s). {messageToUser}
          </p>
        ) : null}
        <Row>
          <Col>
            <div className="physicaladdress-box">
              <p>
                You may <strong>not</strong> enter an address outside of the Bahá’í jurisdiction of the United States. If you are
                moving out of the continental United States, please contact the Membership Office at{' '}
                <a href="mailto:membership@usbnc.org">membership@usbnc.org</a> or <a href="tel:8477333445">(847) 733-3445</a> or
                the Office of Pioneering at <a href="mailto:pioneer@usbnc.org">pioneer@usbnc.org</a>.
              </p>
            </div>
            <p className="center">&quot;*&quot; represents required fields</p>
            <TextField
              required
              id="line1"
              name="line1"
              label="Street Address"
              aria-label="Street Address *"
              inputProps={{ 'data-cy': 'inputLine1', maxLength: '45' }}
              error={!!state.line1.error}
              type="text"
              value={state.line1.value}
              onChange={handleValidationOnChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            {state.line1.error && (
              <p className="form-field-error" data-cy="line1_Required">
                {state.line1.error}
              </p>
            )}
            <TextField
              inputProps={{ 'data-cy': 'inputLine2', maxLength: '45' }}
              error={!!state.line2.error}
              id="line2"
              name="line2"
              label="Address line two"
              aria-label="Address line two"
              type="text"
              value={state.line2.value}
              onChange={handleValidationOnChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              required
              inputProps={{ 'data-cy': 'inputCity', maxLength: '40' }}
              id="city"
              name="city"
              label="City"
              aria-label="City"
              error={!!state.city.error}
              type="text"
              value={state.city.value}
              onChange={handleValidationOnChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            {state.city.error && (
              <p className="form-field-error" data-cy="city_Required">
                {state.city.error}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="no-left-padding">
            <TextField
              required
              inputProps={{ 'data-cy': 'inputZipcode', maxLength: '5' }}
              id="zipcode"
              name="zipcode"
              label="Zip Code"
              aria-label="Zip Code"
              error={!!state.zipcode.error}
              type="text"
              value={state.zipcode.value}
              onChange={handleValidationOnChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <br />
            {state.zipcode.error && (
              <p className="form-field-error" data-cy="zipcode_Required">
                {state.zipcode.error}
              </p>
            )}
          </Col>
          <Col sm={6} className="no-right-padding">
            <FormControl variant="outlined" margin="dense" className="min-width-full" data-cy="form">
              <InputLabel id="state">State *</InputLabel>
              <Select
                required
                labelId="state"
                label="State *"
                aria-label="State"
                value={state.state.value}
                onChange={handleValidationOnChange}
                inputProps={{
                  name: 'state',
                }}
                SelectDisplayProps={({ 'data-cy': 'inputState' } as unknown) as HTMLAttributes<HTMLDivElement>}
                error={state.state.value === '' || !!state.state.error}
              >
                <MenuItem value="" disabled>
                  <em>Please select a state</em>
                </MenuItem>
                {stateOptions.map((item, key: number) => (
                  <MenuItem key={key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {state.state.error && (
              <p className="form-field-error" data-cy="state_Required">
                {state.state.error}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <br />
          <div className="incareof-box">
            <p>
              If your name is not known to the postal service because you live with someone who has a different last name or at an
              institution such as a health care facility, please provide a name we should address to in the box below.
            </p>
          </div>
          <br />
          <TextField
            inputProps={{ 'data-cy': 'inputInCareOf', maxLength: '40' }}
            error={!!state.inCareOf.error}
            id="inCareOf"
            name="inCareOf"
            label="In Care Of"
            aria-label="In Care Of"
            type="text"
            value={state.inCareOf.value}
            onChange={handleValidationOnChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Row>
      </div>
      <div className="medium-top-margin end">
        <input
          type="submit"
          value="Next"
          className="primary-button"
          data-cy="inputsubmit"
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        />
      </div>
    </>
  );
};
export default withContext(EditAddressPage);
