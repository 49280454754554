import React, { useEffect, useState, useCallback } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import {
  dateStringToDisplayDate,
  buildFullName,
  keyValuesToMenuItems,
  valuesToMenuItems,
  keyValueToMenuItem,
} from '../Utilities/UtilityFunctions';
import { CircularProgress } from '@material-ui/core';
import useForm from '../Utilities/useForm';
import { withRouter } from 'react-router-dom';
import { phoneFormat, emailFormat } from '../Utilities/GeneralRegEx';
import RaceEthnicityComponent from './Utilities/RaceEthnicityComponent';
import MembershipNote from './MembershipNote';
import { of, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { COMMON_VALIDATORS } from '../../src/Utilities/validators';

const initialState = {
  Parent2Id: { value: '' },
  Title: { value: '' },
  FirstName: { value: '' },
  MiddleName: { value: '' },
  LastName: { value: '' },
  Suffix: { value: '' },
  NickName: { value: '' },
  Gender: { value: '' },
  CityOfBirth: { value: '' },
  StateOfBirth: { value: '' },
  LanguagePreferenceSelection: { value: '' },
  Email: { value: '', error: '' },
  RequestNoPhoneCall: { value: false },
  HomeNumber: { value: '' },
  CellNumber: { value: '' },
  InCareOfPhysicalAddress: { value: '' },
  InCareOfMailingAddress: { value: '' },
  RequestNoMail: { value: false },
  RaceEthnicitySelections: { value: [] },
  EmergencyContactName: { value: '' },
  EmergencyContactRelationship: { value: '' },
  EmergencyContactPhone: { value: '' },
  EmergencyContactEmail: { value: '' },
};
const validationSchema = {
  Parent2Id: { required: false },
  Title: { required: false },
  FirstName: {
    required: false,
    validator: COMMON_VALIDATORS.name(),
  },
  MiddleName: {
    required: false,
    validator: COMMON_VALIDATORS.name(),
  },
  LastName: {
    required: true,
    validator: COMMON_VALIDATORS.name(),
  },
  Suffix: { required: false },
  NickName: { required: false },
  Gender: { required: true },
  CityOfBirth: { required: false },
  StateOfBirth: { required: false },
  LanguagePreferenceSelection: { required: true },
  Email: {
    required: false,
    nonRemovable: true,
    nonRemovableMessage: 'Email cannot be removed, only changed.',
    validator: { regEx: emailFormat, error: 'Invalid email format' },
  },
  RequestNoPhoneCall: { required: false },
  HomeNumber: {
    required: false,
    validator: { regEx: phoneFormat, error: 'Invalid phone format' },
  },
  CellNumber: {
    required: false,
    validator: { regEx: phoneFormat, error: 'Invalid phone format' },
  },
  InCareOfPhysicalAddress: { required: false },
  InCareOfMailingAddress: { required: false },
  RequestNoMail: { required: false },
  RaceEthnicitySelections: { required: false },
  EmergencyContactName: { required: false },
  EmergencyContactRelationship: { required: false },
  EmergencyContactPhone: {
    required: false,
    validator: { regEx: phoneFormat, error: 'Invalid phone format' },
  },
  EmergencyContactEmail: {
    required: false,
    validator: { regEx: emailFormat, error: 'Invalid email format' },
  },
};

const checkHighlightedFields = 'Please check the highlighted field(s) and resolve the error(s). ';

const GenderUnknown = 'Unknown';

const ChildEdit = (props) => {
  const childIndexString = props.match.params.childIndex;
  const history = props.history;
  const childIndex = parseInt(childIndexString);

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Edit Your Child’s Information'), [setPageTitle]);
  const [triggerUseEffects, setTriggerUseEffects] = useState(0);
  const [messageToUser, setMessageToUser] = useState(null);
  const [badErrorMessage, setBadErrorMessage] = useState(null);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [childInformation, setChildInformation] = useState({});
  const [staticState, setStaticState] = useState({
    childIdn: 0,
    parent1FullName: undefined,
    parent2FullName: undefined,
    emailPending: undefined,
    householdParentMembersMenuItems: undefined,
    stateOfBirthDisplay: undefined,
    language: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    title: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    suffix: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    states: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    countries: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    raceEthnicityOptions: undefined,
  });

  const staticStateChildIdn = staticState.childIdn;

  useEffect(() => {
    if (!(childIndex >= 0)) {
      history.push('/myprofile/household');
    }
  }, [childIndex, history]);

  const myProfileProxy = props.context.myProfileProxy;
  const lookupProxy = props.context.lookupProxy;
  const householdProxy = props.context.householdProxy;
  const emailProxy = props.context.emailProxy;

  // Action to take if useForm determines fields are valid
  const submit = useCallback(
    (state, processErrors) => {
      const putChildEditRequestModel = {
        ChildIdn: staticStateChildIdn,
        ParentOtherId: state.Parent2Id.value,
        Title: state.Title.value,
        FirstName: state.FirstName.value,
        MiddleName: state.MiddleName.value,
        LastName: state.LastName.value,
        Suffix: state.Suffix.value,
        NickName: state.NickName.value,
        Gender: state.Gender.value,
        CityOfBirth: state.CityOfBirth.value,
        StateOfBirth: state.StateOfBirth.value,
        LanguagePreferenceSelection: state.LanguagePreferenceSelection.value,
        RequestNoPhoneCall: state.RequestNoPhoneCall.value,
        HomeNumber: state.HomeNumber.value,
        CellNumber: state.CellNumber.value,
        InCareOfPhysicalAddress: state.InCareOfPhysicalAddress.value,
        InCareOfMailingAddress: state.InCareOfMailingAddress.value,
        RequestNoMail: state.RequestNoMail.value,
        RaceEthnicitySelections: state.RaceEthnicitySelections.value,
        EmergencyContactName: state.EmergencyContactName.value,
        EmergencyContactRelationship: state.EmergencyContactRelationship.value,
        EmergencyContactPhone: state.EmergencyContactPhone.value,
        EmergencyContactEmail: state.EmergencyContactEmail.value,
      };

      setShowCircularProgress(true);
      setMessageToUser(null);

      const myProfileProxySetChildObservable = myProfileProxy.setChild(putChildEditRequestModel);

      const emailProxySetChildObservable = emailProxy.setChild(state.Email.value, staticStateChildIdn);

      const combinedObservables = combineLatest([myProfileProxySetChildObservable, emailProxySetChildObservable]);

      const subscription = combinedObservables.subscribe({
        next: (combinedResponses) => {
          const emailResponse = combinedResponses[1];
          const emailResponseMessage =
            emailResponse?.message && emailResponse.message !== 'No emails changed.' ? emailResponse.message : undefined;
          setMessageToUser(
            <p className="success-message wider" data-cy="success-message">
              Update successful
              {emailResponseMessage ? `. ${emailResponseMessage}` : ''}
            </p>
          );
          setShowCircularProgress(false);
          setTriggerUseEffects((prev) => prev + 1);
        },
        error: (e) => {
          if (e.response?.status === 400) {
            const unprocessedErrors = processErrors(e.response.data);
            const newMessage = `${checkHighlightedFields}${unprocessedErrors}`;
            setMessageToUser(
              <p className="error-message wider" data-cy="unsuccess-message">
                {newMessage}
              </p>
            );
          } else {
            setBadErrorMessage(<ErrorMessageToUser />);
          }
          setShowCircularProgress(false);
        },
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    },
    [myProfileProxy, staticStateChildIdn, emailProxy]
  );

  // Action to take if useForm determines form is invalid
  const onInvalidSubmitAttempt = useCallback(() => {
    setMessageToUser(
      <p data-cy="unsuccess-message" className="error-message wider">
        {checkHighlightedFields}
      </p>
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { state, setState, handleValidationOnChange, handleOnSubmit } = useForm(
    initialState,
    validationSchema,
    submit,
    onInvalidSubmitAttempt
  );

  // Get lookup values and pre-populate email field
  useEffect(() => {
    const householdChildmembersObservable = householdProxy.membersUnder15YearsOld();
    const childInformationObservable = householdChildmembersObservable.pipe(
      switchMap((childMembersResponse) => {
        const selectedChild = childMembersResponse[childIndex];
        const selectedChildIdn = selectedChild?.idn;
        if (!(selectedChildIdn >= 0)) {
          history.push('/myprofile/household');
          return of({});
        }
        return myProfileProxy.getChild(selectedChildIdn).pipe(map((x) => ({ idn: selectedChildIdn, response: x })));
      })
    );

    const myProfileObservable = myProfileProxy.get();
    const countriesObservable = lookupProxy.countries();
    const householdParentMembersObservable = householdProxy.members15YearsAndOlder();
    const emailInformationObservable = householdChildmembersObservable.pipe(
      switchMap((childMembersResponse) => {
        const selectedChild = childMembersResponse[childIndex];
        const selectedChildIdn = selectedChild?.idn;
        if (!(selectedChildIdn >= 0)) {
          history.push('/myprofile/household');
          return of({});
        }
        return emailProxy.getChild(selectedChildIdn);
      })
    );

    const combinedObservables = combineLatest([
      myProfileObservable,
      countriesObservable,
      childInformationObservable,
      householdParentMembersObservable,
      emailInformationObservable,
    ]);

    const subscription = combinedObservables.subscribe({
      next: (res) => {
        const myProfileResponse = res[0];
        const countriesResponse = res[1];
        const childInformationResponse = res[2].response;
        const emailInformation = res[4];
        const childIdn = res[2].idn;
        const householdParentMembers = res[3];
        const householdParentMembersMenuItems = householdParentMembers
          .map((householdMember) => ({
            key: householdMember.idn,
            value: buildFullName(householdMember),
          }))
          .map(keyValuesToMenuItems);

        const parent1FullName = buildFullName(childInformationResponse.parentSelf);
        const parent2FullName =
          childInformationResponse.parentOther?.lastName?.length > 0 ? buildFullName(childInformationResponse.parentOther) : '';

        const newInitialState = {
          Parent2Id: { value: childInformationResponse.parentOther?.id ?? '' },
          Title: { value: childInformationResponse.title ?? '' },
          FirstName: { value: childInformationResponse.firstName ?? '' },
          MiddleName: { value: childInformationResponse.middleName ?? '' },
          LastName: { value: childInformationResponse.lastName ?? '' },
          Suffix: { value: childInformationResponse.suffix ?? '' },
          NickName: { value: childInformationResponse.nickName ?? '' },
          Gender: {
            value:
              childInformationResponse.gender?.length > 0 && childInformationResponse.gender !== GenderUnknown
                ? childInformationResponse.gender
                : '',
          },
          CityOfBirth: { value: childInformationResponse.cityOfBirth ?? '' },
          StateOfBirth: { value: childInformationResponse.stateOfBirth ?? '' },
          LanguagePreferenceSelection: {
            value: childInformationResponse.languagePreference,
          },
          Email: {
            value: emailInformation.email ?? '',
            initialValue: emailInformation.email,
          },
          RequestNoPhoneCall: {
            value: childInformationResponse.requestNoPhoneCall,
          },
          HomeNumber: { value: childInformationResponse.homePhone },
          CellNumber: { value: childInformationResponse.cellPhone },
          InCareOfPhysicalAddress: {
            value: childInformationResponse.physicalAddress?.careOf ?? '',
          },
          InCareOfMailingAddress: {
            value: childInformationResponse.mailingAddress?.careOf ?? '',
          },
          RequestNoMail: { value: childInformationResponse.requestNoMail },
          RaceEthnicitySelections: {
            value: childInformationResponse.raceEthnicitySelections ?? [],
          },
          EmergencyContactName: {
            value: childInformationResponse.emergencyContact.emergencyContactName ?? '',
          },
          EmergencyContactRelationship: {
            value: childInformationResponse.emergencyContact.emergencyContactRelationship ?? '',
          },
          EmergencyContactPhone: {
            value: childInformationResponse.emergencyContact.emergencyContactPhone ?? '',
          },
          EmergencyContactEmail: {
            value: childInformationResponse.emergencyContact.emergencyContactEmail ?? '',
          },
        };

        const languages = myProfileResponse.languagePreferenceOptions.map(valuesToMenuItems);
        const titles = myProfileResponse.titleOptions.map(keyValuesToMenuItems);
        const suffixes = myProfileResponse.suffixOptions.map(keyValuesToMenuItems);
        const states = myProfileResponse.stateOptions.map(keyValuesToMenuItems);
        const stateOfBirthPair = myProfileResponse.stateOptions?.find((x) => x.key === childInformationResponse.stateOfBirth);
        const stateOfBirthValue = stateOfBirthPair ? stateOfBirthPair.value : '';
        const countries = countriesResponse.map(keyValuesToMenuItems);
        const raceEthnicityOptions = myProfileResponse.raceEthnicityOptions;
        setStaticState({
          childIdn: childIdn,
          parent1FullName: parent1FullName,
          parent2FullName: parent2FullName,
          emailPending: emailInformation.emailPending,
          householdParentMembersMenuItems: householdParentMembersMenuItems,
          stateOfBirthDisplay: stateOfBirthValue,
          language: languages,
          title: titles,
          suffix: suffixes,
          states: states,
          countries: countries,
          raceEthnicityOptions: raceEthnicityOptions,
        });
        setChildInformation(childInformationResponse);
        setState(newInitialState);
        setShowCircularProgress(false);
      },
      error: () => {
        setBadErrorMessage(<ErrorMessageToUser />);
        setShowCircularProgress(false);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, lookupProxy, householdProxy, setState, childIndex, history, emailProxy, triggerUseEffects]);

  return (
    <div>
      {badErrorMessage ?? (
        <div>
          {showCircularProgress ? (
            <CircularProgress data-cy="child-edit-circular-progress" className="loading-animation" size={40} />
          ) : (
            messageToUser
          )}
          <div className="profile-box">
            <h4>Parent/Legal Guardian Information</h4>
            <Row between="sm">
              <Col sm={4}>
                <p>Parent/Legal Guardian 1</p>
              </Col>
              <Col sm={3}>
                <p>{childInformation.parentSelf?.id ?? 'Loading...'}</p>
              </Col>
              <Col sm={5}>
                <p>
                  <strong>{staticState.parent1FullName}</strong>
                </p>
              </Col>
            </Row>
            {staticState.householdParentMembersMenuItems?.length > 0 ? (
              <Row between="sm">
                <Col sm={4} className="inline-vertical-center">
                  <p>Parent/Legal Guardian 2</p>
                </Col>
                <Col sm={3}>
                  <p data-cy="Parent2Id_display">
                    {childInformation.parentOther?.id > 0 ? childInformation.parentOther.id : state.Parent2Id.value ?? ''}
                  </p>
                </Col>
                <Col sm={5}>
                  {childInformation.parentOther?.id > 0 ? (
                    <p>
                      <strong>{staticState.parent2FullName}</strong>
                    </p>
                  ) : (
                    <span>
                      <FormControl className="full-width">
                        <Select
                          inputProps={{
                            name: 'Parent2Id',
                            'data-cy': 'Parent2Id',
                          }}
                          id="Parent2Id"
                          name="Parent2Id"
                          aria-label="Parent 2 Bahá’í ID number"
                          error={!!state.Parent2Id.error}
                          value={state.Parent2Id.value}
                          onChange={handleValidationOnChange}
                          variant="outlined"
                          margin="dense"
                        >
                          {staticState.householdParentMembersMenuItems}
                        </Select>
                      </FormControl>
                      {state.Parent2Id.error && (
                        <p className="form-field-error" data-cy="Parent2Id_field_error">
                          {state.Parent2Id.error}
                        </p>
                      )}
                    </span>
                  )}
                </Col>
              </Row>
            ) : null}
            <hr />
            <h4>Residential Address</h4>
            <Row className="small-bottom-padding">
              <Col sm={4}>
                <TextField
                  inputProps={{ 'data-cy': 'InCareOfPhysicalAddress' }}
                  id="InCareOfPhysicalAddress"
                  name="InCareOfPhysicalAddress"
                  label="In Care Of"
                  aria-label="In Care Of For Residential Address"
                  type="text"
                  error={!!state.InCareOfPhysicalAddress.error}
                  value={state.InCareOfPhysicalAddress.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.InCareOfPhysicalAddress.error && (
                  <p className="form-field-error" data-cy="InCareOfPhysicalAddress_field_error">
                    {state.InCareOfPhysicalAddress.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="small-bottom-padding">
              <Col sm={4}>
                <p className="faded-text small-text">Street Address</p>
                <p>
                  <strong>{childInformation.physicalAddress?.line1 ?? ''}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">Address Line two</p>
                <p>
                  <strong>{childInformation.physicalAddress?.line2 ?? ''}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">Address Line three</p>
                <p>
                  <strong>{childInformation.physicalAddress?.line3 ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <p className="faded-text small-text">City</p>
                <p>
                  <strong>{childInformation.physicalAddress?.city ?? ''}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">State</p>
                <p>
                  <strong>{childInformation.physicalAddress?.state ?? ''}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">Zip Code</p>
                <p>
                  <strong>{childInformation.physicalAddress?.zipCode ?? ''}</strong>
                </p>
              </Col>
            </Row>
            {childInformation.mailingAddress?.pkAddress !== childInformation.physicalAddress?.pkAddress ? (
              <div>
                <h4>Mailing Address</h4>
                <Row className="small-bottom-padding">
                  <Col sm={4}>
                    <TextField
                      inputProps={{ 'data-cy': 'InCareOfMailingAddress' }}
                      id="InCareOfMailingAddress"
                      name="InCareOfMailingAddress"
                      label="In Care Of"
                      aria-label="In Care Of For Mailing Address"
                      type="text"
                      error={!!state.InCareOfMailingAddress.error}
                      value={state.InCareOfMailingAddress.value}
                      onChange={handleValidationOnChange}
                      margin="dense"
                      maxLength="40"
                      fullWidth={true}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                    {state.InCareOfMailingAddress.error && (
                      <p className="form-field-error" data-cy="InCareOfMailingAddress_field_error">
                        {state.InCareOfMailingAddress.error}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row className="small-bottom-padding">
                  <Col sm={4}>
                    <p className="faded-text small-text">Street Address</p>
                    <p>
                      <strong>{childInformation.mailingAddress?.line1 ?? ''}</strong>
                    </p>
                  </Col>
                  <Col sm={4}>
                    <p className="faded-text small-text">Address Line two</p>
                    <p>
                      <strong>{childInformation.mailingAddress?.line2 ?? ''}</strong>
                    </p>
                  </Col>
                  <Col sm={4}>
                    <p className="faded-text small-text">Address Line three</p>
                    <p>
                      <strong>{childInformation.mailingAddress?.line3 ?? ''}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <p className="faded-text small-text">City</p>
                    <p>
                      <strong>{childInformation.mailingAddress?.city ?? ''}</strong>
                    </p>
                  </Col>
                  <Col sm={4}>
                    <p className="faded-text small-text">State</p>
                    <p>
                      <strong>{childInformation.mailingAddress?.state ?? ''}</strong>
                    </p>
                  </Col>
                  <Col sm={4}>
                    <p className="faded-text small-text">Zip Code</p>
                    <p>
                      <strong>{childInformation.mailingAddress?.zipCode ?? ''}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null}
            <Row>
              <Col>
                <label aria-label="RequestNoMail" className="medium-left-padding">
                  <input
                    id="RequestNoMail"
                    name="RequestNoMail"
                    type="checkbox"
                    data-cy="RequestNoMail"
                    checked={state.RequestNoMail.value}
                    onChange={handleValidationOnChange}
                  ></input>{' '}
                  Request No Mail by Post
                </label>
                {state.RequestNoMail.error && (
                  <p className="form-field-error" data-cy="RequestNoMail_field_error">
                    {state.RequestNoMail.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Child’s Name</h4>
            <Row className="small-bottom-padding" between="xs">
              <Col sm className="dense-col-padding">
                <FormControl variant="outlined" margin="dense" className="small-dropdown">
                  <InputLabel id="Title" className="small-text">
                    Title
                  </InputLabel>
                  <Select
                    error={!!state.Title.error}
                    value={state.Title.value}
                    onChange={handleValidationOnChange}
                    label="Title"
                    labelId="Title"
                    inputProps={{ name: 'Title', 'data-cy': 'Title' }}
                  >
                    {staticState.title}
                  </Select>
                </FormControl>
                {state.Title.error && (
                  <p className="form-field-error" data-cy="Title_field_error">
                    {state.Title.error}
                  </p>
                )}
              </Col>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'FirstName' }}
                  id="FirstName"
                  name="FirstName"
                  label="First Name"
                  aria-label="First Name"
                  type="text"
                  error={!!state.FirstName.error}
                  value={state.FirstName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.FirstName.error && (
                  <p className="form-field-error" data-cy="FirstName_field_error">
                    {state.FirstName.error}
                  </p>
                )}
              </Col>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'MiddleName' }}
                  id="MiddleName"
                  name="MiddleName"
                  label="Middle Name"
                  aria-label="Middle Name"
                  type="text"
                  error={!!state.MiddleName.error}
                  value={state.MiddleName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.MiddleName.error && (
                  <p className="form-field-error" data-cy="MiddleName_field_error">
                    {state.MiddleName.error}
                  </p>
                )}
              </Col>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  required
                  inputProps={{ 'data-cy': 'LastName' }}
                  id="LastName"
                  name="LastName"
                  label="Last Name"
                  aria-label="Last Name"
                  error={!!state.LastName.error}
                  type="text"
                  value={state.LastName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.LastName.error && (
                  <p className="form-field-error" data-cy="LastName_field_error">
                    {state.LastName.error}
                  </p>
                )}
              </Col>
              <Col xs className="dense-col-padding">
                <FormControl variant="outlined" margin="dense" className="small-dropdown">
                  <InputLabel id="Suffix" className="small-text">
                    Suffix
                  </InputLabel>
                  <Select
                    error={!!state.Suffix.error}
                    value={state.Suffix.value}
                    onChange={handleValidationOnChange}
                    label="Suffix"
                    labelId="Suffix"
                    inputProps={{ name: 'Suffix', 'data-cy': 'Suffix' }}
                  >
                    {staticState.suffix}
                  </Select>
                </FormControl>
                {state.Suffix.error && (
                  <p className="form-field-error" data-cy="Suffix_field_error">
                    {state.Suffix.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'NickName' }}
                  id="NickName"
                  name="NickName"
                  label="Known as/Nickname"
                  aria-label="Known as/Nickname"
                  type="text"
                  error={!!state.NickName.error}
                  value={state.NickName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.NickName.error && (
                  <p className="form-field-error" data-cy="NickName_field_error">
                    {state.NickName.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Personal Information</h4>
            <Row>
              <Col sm={6}>
                {childInformation.gender?.length > 0 && childInformation.gender !== GenderUnknown ? (
                  <div>
                    <Row>
                      <Col sm={5} className="profile-alignment inline-vertical-center">
                        <p>Gender</p>
                      </Col>
                      <Col sm={7}>
                        <p>
                          <strong>{childInformation.gender}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col sm={5} className="profile-alignment inline-vertical-center">
                        <p>*Gender</p>
                      </Col>
                      <Col sm={7}>
                        <FormControl className="full-width">
                          <Select
                            required
                            inputProps={{ name: 'Gender', 'data-cy': 'Gender' }}
                            id="Gender"
                            name="Gender"
                            aria-label="Gender"
                            variant="outlined"
                            margin="dense"
                            className="spaced-dropdowns"
                            error={!!state.Gender.error}
                            value={state.Gender.value}
                            onChange={handleValidationOnChange}
                          >
                            <MenuItem selected disabled data-cy={`Gender_Disabled`} value=""></MenuItem>
                            <MenuItem data-cy={`Gender_Female`} value="Female">
                              Female
                            </MenuItem>
                            <MenuItem data-cy={`Gender_Male`} value="Male">
                              Male
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {state.Gender.error && (
                          <p className="form-field-error" data-cy="Gender_field_error">
                            {state.Gender.error}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>Date of Birth</p>
                  </Col>
                  <Col sm={7}>
                    <p>
                      <strong>
                        {childInformation.birthDate?.length > 0 ? dateStringToDisplayDate(childInformation.birthDate) : ''}
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>City of Birth</p>
                  </Col>
                  <Col sm={7}>
                    {childInformation.cityOfBirth?.length > 0 ? (
                      <p>
                        <strong>{childInformation.cityOfBirth}</strong>
                      </p>
                    ) : (
                      <span>
                        <TextField
                          inputProps={{ 'data-cy': 'CityOfBirth' }}
                          id="CityOfBirth"
                          name="CityOfBirth"
                          aria-label="City of Birth"
                          type="text"
                          error={!!state.CityOfBirth.error}
                          value={state.CityOfBirth.value}
                          onChange={handleValidationOnChange}
                          variant="outlined"
                          margin="dense"
                          maxLength="40"
                        />
                        {state.CityOfBirth.error && (
                          <p className="form-field-error" data-cy="CityOfBirth_field_error">
                            {state.CityOfBirth.error ?? null}
                          </p>
                        )}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>State of Birth</p>
                  </Col>
                  <Col sm={7}>
                    {childInformation.stateOfBirth?.length > 0 ? (
                      <p>
                        <strong>{staticState.stateOfBirthDisplay}</strong>
                      </p>
                    ) : (
                      <span>
                        <FormControl className="full-width">
                          <Select
                            inputProps={{
                              name: 'StateOfBirth',
                              'data-cy': 'StateOfBirth',
                            }}
                            id="StateOfBirth"
                            name="StateOfBirth"
                            aria-label="State Of Birth"
                            error={!!state.StateOfBirth.error}
                            value={state.StateOfBirth.value}
                            onChange={handleValidationOnChange}
                            variant="outlined"
                            margin="dense"
                            className="spaced-dropdowns"
                          >
                            {staticState.states}
                          </Select>
                        </FormControl>
                        {state.StateOfBirth.error && (
                          <p className="form-field-error" data-cy="StateOfBirth_field_error">
                            {state.StateOfBirth.error}
                          </p>
                        )}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>Country of Birth</p>
                  </Col>
                  <Col sm={7}>
                    <p>
                      <strong>{childInformation.countryOfBirth}</strong>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <RaceEthnicityComponent
                  raceEthnicityOptions={staticState.raceEthnicityOptions}
                  value={state.RaceEthnicitySelections.value}
                  onChange={handleValidationOnChange}
                  name="RaceEthnicitySelections"
                />
              </Col>
            </Row>
            <hr />
            <h4>Emergency Contact</h4>
            <Row className="small-bottom-padding">
              <Col sm={6}>
                <TextField
                  inputProps={{ 'data-cy': 'EmergencyContactName' }}
                  id="EmergencyContactName"
                  name="EmergencyContactName"
                  label="Name"
                  aria-label="Emergency Contact Name"
                  type="text"
                  error={!!state.EmergencyContactName.error}
                  value={state.EmergencyContactName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.EmergencyContactName.error && (
                  <p className="form-field-error" data-cy="EmergencyContactName_field_error">
                    {state.EmergencyContactName.error}
                  </p>
                )}
              </Col>
              <Col sm={6}>
                <TextField
                  inputProps={{ 'data-cy': 'EmergencyContactRelationship' }}
                  id="EmergencyContactRelationship"
                  name="EmergencyContactRelationship"
                  label="Relationship"
                  aria-label="Emergency Contact Relationship"
                  type="text"
                  error={!!state.EmergencyContactRelationship.error}
                  value={state.EmergencyContactRelationship.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.EmergencyContactRelationship.error && (
                  <p className="form-field-error" data-cy="EmergencyContactRelationship_field_error">
                    {state.EmergencyContactRelationship.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <TextField
                  inputProps={{ 'data-cy': 'EmergencyContactEmail' }}
                  id="EmergencyContactEmail"
                  name="EmergencyContactEmail"
                  label="Email"
                  aria-label="Emergency Contact Email"
                  type="text"
                  error={!!state.EmergencyContactEmail.error}
                  value={state.EmergencyContactEmail.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.EmergencyContactEmail.error && (
                  <p className="form-field-error" data-cy="EmergencyContactEmail_field_error">
                    {state.EmergencyContactEmail.error}
                  </p>
                )}
              </Col>
              <Col sm={6}>
                <TextField
                  inputProps={{ 'data-cy': 'EmergencyContactPhone' }}
                  id="EmergencyContactPhone"
                  name="EmergencyContactPhone"
                  label="Phone"
                  aria-label="Emergency Contact Phone"
                  type="text"
                  error={!!state.EmergencyContactPhone.error}
                  value={state.EmergencyContactPhone.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.EmergencyContactPhone.error && (
                  <p className="form-field-error" data-cy="EmergencyContactPhone_field_error">
                    {state.EmergencyContactPhone.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Membership information</h4>
            <Row>
              <Col sm={5} className="profile-alignment inline-vertical-center">
                <p className="faded-text small-text">Parent Sign Date</p>
              </Col>
              <Col sm={7}>
                <p>
                  <strong>
                    {childInformation.parentSignDate?.length > 0 ? dateStringToDisplayDate(childInformation.parentSignDate) : ''}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="profile-alignment inline-vertical-center">
                <p className="faded-text small-text">Recognition Date</p>
              </Col>
              <Col sm={7}>
                <p>
                  <strong>
                    {childInformation.recognitionDate?.length > 0
                      ? dateStringToDisplayDate(childInformation.recognitionDate)
                      : ''}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="profile-alignment inline-vertical-center">
                <p className="faded-text small-text">Recognition Type</p>
              </Col>
              <Col sm={7}>
                <p>
                  <strong>{childInformation.recognitionType ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="profile-alignment inline-vertical-center">
                <p className="faded-text small-text">Recognition Community</p>
              </Col>
              <Col sm={7}>
                <p>
                  <strong>{childInformation.recognitionCommunity ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="profile-alignment inline-vertical-center">
                <p className="faded-text small-text">Member Type</p>
              </Col>
              <Col sm={7}>
                <p>
                  <strong>{childInformation.memberType ?? ''}</strong>
                </p>
              </Col>
            </Row>
            {childInformation.isRefugee ? (
              <Row>
                <Col sm={5} className="profile-alignment inline-vertical-center">
                  <p className="faded-text small-text">Status</p>
                </Col>
                <Col sm={7}>
                  <p>
                    <strong>Child is Refugee</strong>
                  </p>
                </Col>
              </Row>
            ) : null}
            <hr />
            <Row middle="sm">
              <Col sm={3} className="inline-vertical-center small-left-padding medium-top-padding">
                <h4>Language Preference</h4>
              </Col>
              <Col sm={5}>
                <FormControl className="full-width">
                  <Select
                    required
                    error={!!state.LanguagePreferenceSelection.error}
                    value={state.LanguagePreferenceSelection.value}
                    onChange={handleValidationOnChange}
                    inputProps={{
                      name: 'LanguagePreferenceSelection',
                      'data-cy': 'LanguagePreferenceSelection',
                    }}
                    variant="outlined"
                    margin="dense"
                    maxLength="40"
                    fullWidth={true}
                  >
                    {staticState.language}
                  </Select>
                </FormControl>
                {state.LanguagePreferenceSelection.error && (
                  <p className="form-field-error" data-cy="LanguagePreferenceSelection_field_error">
                    {state.LanguagePreferenceSelection.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Phone Numbers</h4>
            <Row>
              <Col sm={4} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'HomeNumber' }}
                  id="HomeNumber"
                  name="HomeNumber"
                  label="Home Phone"
                  aria-label="Home Phone"
                  type="text"
                  error={!!state.HomeNumber.error}
                  value={state.HomeNumber.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.HomeNumber.error && (
                  <p className="form-field-error" data-cy="HomeNumber_field_error">
                    {state.HomeNumber.error}
                  </p>
                )}
              </Col>
              <Col sm={4} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'CellNumber' }}
                  id="CellNumber"
                  name="CellNumber"
                  label="Cell Phone"
                  aria-label="Cell Phone"
                  type="text"
                  error={!!state.CellNumber.error}
                  value={state.CellNumber.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.CellNumber.error && (
                  <p className="form-field-error" data-cy="CellNumber_field_error">
                    {state.CellNumber.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Email</h4>
            <Row>
              <Col sm={4} className="dense-col-padding">
                <TextField
                  id="Email"
                  name="Email"
                  aria-label="Email"
                  type="email"
                  variant="outlined"
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  inputProps={{ 'data-cy': 'Email' }}
                  InputLabelProps={{ shrink: true }}
                  error={!!state.Email.error}
                  value={state.Email.value ?? ''}
                  onChange={handleValidationOnChange}
                />
                {state.Email.error && (
                  <p className="form-field-error" data-cy="Email_field_error">
                    {state.Email.error}
                  </p>
                )}
                {staticState.emailPending && (
                  <p data-cy="email_pending" className="small-text attention very-small-top-padding">
                    The following email address is waiting for you to confirm: <strong>{staticState.emailPending}</strong>.
                  </p>
                )}
              </Col>
              <Col sm={8} className="small-text inline-vertical-center">
                <p>
                  <em>This address is used for correspondence from Bahá’í agencies.</em>
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={9} className="medium-top-margin no-padding">
              <MembershipNote />
            </Col>
            <Col sm={3} className="no-padding medium-top-margin end">
              <div className="">
                <input onClick={handleOnSubmit} type="submit" value="Save" className="primary-button" data-cy="inputsubmit" />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default withRouter(withContext(ChildEdit));
