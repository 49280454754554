import React, { ChangeEvent, FormEvent, JSX, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { withStyles, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import PasswordViewControl from '../../Utilities/PasswordViewControl';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});
interface SetNewPasswordProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  displayMessage: JSX.Element;
  success: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
function SetNewPassword({ success, displayMessage, handleSubmit, handleChange }: SetNewPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isVisible = success ? false : true;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [displayMessage]);

  return (
    <Row around="xs" className="faded-background-medium">
      <Col xs={12} className="form-style wide-form">
        <form onSubmit={handleSubmit}>
          <h3>Set New Password</h3>
          {displayMessage}
          <span data-cy="setNewPasswordFormContainer" className={isVisible ? 'show' : 'hide'}>
            <ul className="plain-list no-margin large-top-padding">
              <li>Passwords must be at least 8 characters long and contain at least 3 of the following:</li>
              <li>One lower case letter</li>
              <li>One UPPER case letter</li>
              <li>One number</li>
              <li>One special character such as !@$%^*</li>
              <li>It will NOT accept the following 3 characters # &amp; &lt;</li>
            </ul>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" className="top-margin">
                Password
              </InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name="Password"
                className="top-margin"
                inputProps={{ 'data-cy': 'password' }}
                onChange={handleChange}
                endAdornment={
                  <PasswordViewControl showPassword={showPassword} setShowPassword={setShowPassword} datacy="passwordEye" />
                }
                labelWidth={70}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" className="top-margin">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={showConfirmPassword ? 'text' : 'password'}
                name="ConfirmPassword"
                className="top-margin"
                inputProps={{ 'data-cy': 'confirmpassword' }}
                onChange={handleChange}
                endAdornment={
                  <PasswordViewControl
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                    datacy="confirmPasswordEye"
                  />
                }
                labelWidth={132}
              />
            </FormControl>
            <Row around="xs" className="top-margin row-reverse">
              <Col>
                <input
                  data-cy="setNewPasswordSubmitButton"
                  type="submit"
                  value="Submit"
                  id="setNewPasswordSubmitButton"
                  className="primary-button"
                />
              </Col>
              <Col>
                <Link data-cy="cancel-button" className="secondary-button" to="/login">
                  Cancel
                </Link>
              </Col>
            </Row>
          </span>
        </form>
        <span data-cy="setNewPasswordBackToLoginContainer" className={isVisible ? 'hide' : 'show'}>
          <p>
            <NavLink data-cy="setNewPasswordBackToLogin" to="/login">
              &lt; back to login
            </NavLink>
          </p>
        </span>
      </Col>
    </Row>
  );
}
export default withStyles(styles, { withTheme: true })(SetNewPassword);
