import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import withContext from '../../ContextAPI/Context_HOC';
import FundHeart from '../../assets/img/FundHeart.png';
import Education from '../../assets/img/FundEducation/Education.png';
import TreasurersTools from '../../assets/img/TreasurersTools/TreasurersTools.png';
import Events from '../../assets/img/FundDev/UpcomingEvents.png';
import Fundraisers from '../../assets/img/FundDev/FundraisingCampaign.png';
import PlannedGiving from '../../assets/img/FundDev/PlannedGiving.png';
import FundBulletin from '../../assets/img/FundDev/FundBulletin.png';
import { OptimizelyFeature } from '@optimizely/react-sdk';

class FundResources extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} lg={2}>
                <img src={FundHeart} alt="Baha’i Fund" className="fund-logo" />
              </Col>
              <Col xs={9} lg={10}>
                <h1>Fund Resources</h1>
              </Col>
            </Row>
            <p>
              To stay connected to the Fund, text 224-414-FUND (3863) and in the message box write &ldquo;Bahaifund&ldquo;. Have
              questions? After signing up, Text 224-414-FUND (3863), and in the message box, write your question.
            </p>
            <iframe
              src="https://player.vimeo.com/video/723216949?h=2089eeef16"
              width="100%"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col xs={12} md={6}>
            <Link to="/resources/treasurers-tools/fund-education" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={Education} alt="Fund Education" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Education on the Bahá’í Fund</h4>
                  <p>
                    Understanding our Sacred Obligation, Stewardship and Development, Understanding the National Bahá’í Fund Goal,
                    Prayers and Writings on the Bahá’í Fund, Inspirational Stories, Videos on the Fund, Contribution Forms
                  </p>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/treasurers-tools" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={TreasurersTools} alt="Treasurers Tools" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Treasurer’s Tools</h4>
                  <p>
                    Accounting, Audit, Treasurers’ Forms Downloadable Documents, Education on the Bahá’í Fund, Bahá’í Fund
                    Bulletin, and Question and Concerns
                  </p>
                </Col>
              </Row>
            </Link>
            <br />
            <OptimizelyFeature feature="fund_events">
              {(isEnabled) =>
                isEnabled ? (
                  <>
                    <Link to="/resources/fund/upcoming-events" className="plain-link">
                      <Row className="fund-rows">
                        <Col xs={3} lg={2}>
                          <img src={Events} alt="Events" className="fund-icons" />
                        </Col>
                        <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                          <h4>Upcoming Events and Programs</h4>
                          <p>Treasurers Forum, Webinars, Conferences, Fundraisers, Persian Bahá’í Media Services, etc...</p>
                        </Col>
                      </Row>
                    </Link>
                    <br />
                  </>
                ) : null
              }
            </OptimizelyFeature>
            <OptimizelyFeature feature="archives_project">
              {(isEnabled) =>
                isEnabled ? (
                  <>
                    <Link to="/resources/fund/archives-project" className="plain-link">
                      <Row className="fund-rows">
                        <Col xs={3} lg={2}>
                          <img src={Fundraisers} alt="Fundraisers" className="fund-icons" />
                        </Col>
                        <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                          <h4>Archives Project</h4>
                          <p>Updates and Funding</p>
                        </Col>
                      </Row>
                    </Link>
                    <br />
                  </>
                ) : null
              }
            </OptimizelyFeature>
            <Link to="/resources/planned-giving" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={PlannedGiving} alt="Planned Giving" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Planned Giving</h4>
                  <p>
                    Leaving a Legacy to the Bahá’í Faith through Planned Giving, Charitable Gift Annuities, Bequests/Wills,
                    Securities, Individual Retirement, Donor Advised Funds, Real Estate, and Questions and Answers
                  </p>
                </Col>
              </Row>
            </Link>
            <br />
            <a target="_blank" rel="noopener noreferrer" href="https://bahaitreasurer.us/feast-resourses" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={FundBulletin} alt="Bahá’í Fund Bulletin" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Bahá’í Fund Bulletin</h4>
                  <p>Newsletter with News, Announcement, and Education on the Bahá’í Fund provided for every Feast</p>
                </Col>
              </Row>
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withContext(FundResources);
