import React from 'react';

function PublicAffairs() {
  return (
    <div>
      <h1>The U.S. Bahá’í Office of Public Affairs</h1>
      <p>
        Information and resources related to the development and management of the relationships of the Bahá’í community with
        governments, organizations of civil society, the media, prominent people, and the public.
      </p>
      <p>
        <a href="tel:202-833-8990">(202) 833-8990</a>
        <br />
        <a href="mailto:bahaisus@usbnc.org">bahaisus@usbnc.org</a>
      </p>
      <blockquote>
        “Be anxiously concerned with the needs of the age ye live in, and center your deliberations on its exigencies and
        requirements.” -Bahá’u’lláh
      </blockquote>
      <hr />
      <p>
        The National Spiritual Assembly’s (NSA) public affairs work is managed by its Office of Public Affairs (OPA) in
        Washington, D.C., which is part of the NSA’s Secretariat. Should any questions arise concerning any aspects of local or
        national public affairs work, please feel free to contact this office by email to{' '}
        <a href="mailto:bahaisus@usbnc.org">bahaisus@usbnc.org</a> or by phone <a href="tel:202-833-8990">(202) 833-8990</a>.
      </p>
      <p>
        Public affairs refers to the development and management of the relationships of the Bahá’í community with governments,
        organizations of civil society, the media, prominent people, and the public. Public affairs is conducted by the elected
        institutions of the Faith on behalf of the Bahá’í community. This assures that the public face of the Faith is consistent
        and dignified, and accurately portrays Bahá’í principles and positions.
      </p>
      <h3>Functions of OPA</h3>
      <ul>
        <li>Relations with the federal government and state governments</li>
        <li>Contact and collaboration with national-level non-governmental organizations</li>
        <li>Outreach to national-level media outlets</li>
        <li>Representation of the Bahá’í community at the national level in public forums</li>
      </ul>
      <hr />
      <h3>OPA is responsible for the U.S. Bahá’í community’s work in a variety of areas</h3>
      <ul>
        <li>Defense of the Bahá’ís in Iran and other countries</li>
        <li>
          Human rights, including international religious freedom, U.S. ratification of international human rights treaties and
          conventions, and U.N. funding and reform
        </li>
        <li>
          The advancement of women, including U.S. ratification of an international treaty on the rights of women, advocacy
          efforts for the prevention of violence against women, the engagement of men and boys in promoting gender equity, and
          gender in development and foreign aid
        </li>
        <li>
          Sustainable development and global prosperity, including global climate change, sustainable consumption and production,
          and the elimination of extremes of wealth and poverty
        </li>
        <li>Interfaith activities at the national level</li>
        <li>Public Information Officers, for issues involving public affairs matters, including the bulleted items above.</li>
      </ul>
      <hr />
      <h3>OPA oversees</h3>
      <ul>
        <li>Coordination of local Bahá’í contact with federal and state government officials and with prominent individuals</li>
        <li>
          Guidance to Local Spiritual Assemblies, communities, and individuals regarding local and national public affairs issues,
          opportunities and activities
        </li>
        <li>Public affairs training for Regional Bahá’í Councils, Local Spiritual Assemblies and individuals</li>
      </ul>
      <hr />
      <h3>Public Affairs Guidelines and Materials</h3>
      <p>
        The documents below provide guidance regarding what public affairs work means within the Bahá’í context, as well as how
        individuals and communities can participate in public affairs work under the supervision of the institutions of the Faith.
      </p>
      <p>Public Affairs Guidelines:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Public-Affairs-Policies-Guidelines-2017.pdf">
            Public Affairs Policies and Guidelines 2017 (PDF)
          </a>
        </li>
      </ul>
      <p>Desk Reference Materials:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Functions-of-the-Office-of-Public-Affairs.pdf">
            Functions of the Office of Public Affairs (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Guide-to-Local-Public-Affairs-Opportunities.pdf">
            Guide to Local Public Affairs Opportunities (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Institutional-Supervision-of-Public-Affairs.pdf">
            Institutional Supervision of Public Affairs (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/National-Spiritual-Assembly-Policy-on-Bahai-Contact-with-Government.pdf">
            National Spiritual Assembly Policy on Bahá’í Contact with Government (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/PowerPoint-Presentation-on-Public-Affairs.pptx">
            PowerPoint presentation on Public Affairs (PPTX)
          </a>{' '}
          - <em>updated October 2022</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Public-Affairs-and-Teaching-FAQs.pdf">
            Public Affairs and Teaching FAQs (PDF)
          </a>
        </li>
      </ul>
      <p>Public Information Officer (PIO) Materials:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Model-Assembly-Checklist.docx">Model Assembly Checklist (DOCX)</a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/PIO-Vision-and-Mandate.docx">PIO Vision and Mandate (DOCX)</a>
        </li>
      </ul>
      <hr />
      <h3>Materials for Bahá’í Delegations Doing Congressional Outreach</h3>
      <p>
        For more than 13 years, the National Spiritual Assembly, through its Office of Public Affairs, has requested the
        assistance of selected Local Spiritual Assemblies in reaching out to specific members of Congress on behalf of our
        beleaguered Bahá’í brothers and sisters in Iran. These Assemblies have been asked to appoint and supervise delegations to
        visit the offices of their senators and representatives, provide information and updates about the persecution of the
        Bahá’ís in Iran, and ask that their legislators do whatever they can to shine a light on the issue, including cosponsoring
        resolutions introduced in each Congress. For press releases about recent developments click &quot;Newsroom&quot; on the
        home page.
      </p>
      <p>
        Assemblies that have been selected to supervise this outreach should be sure to review their assignment letters closely
        and complete the PowerPoint training before appointing and supervising delegations, as this work is highly nuanced and
        requires close adherence to the instructions given. This training should be completed annually for both Assembly and
        delegation members. The additional materials below are designed to be used only by these selected Assemblies and their
        appointed delegations.
      </p>
      <p>
        If you are unsure whether your Assembly currently has an assignment, please view the documents below, entitled “LSA
        Congressional Assignments – Senate” and “LSA Congressional Assignments – House of Representatives,” to determine whether
        your Assembly has been assigned to a member of Congress. Please be sure to check both documents, as your Assembly may be
        assigned to more than one member of Congress (i.e., a Senator and a Representative, or two Senators, and/or two or more
        Representatives). Additionally, please note that a few select Assemblies have been assigned to Representatives in
        neighboring states.
      </p>
      <p>
        Instructions from the Office of Public Affairs for Bahá’í delegations and the Assemblies assigned to appoint and supervise
        their work should not be posted to social media or other online or public venues, and should not be shared with
        congressional offices. Should you have any questions or require further assistance, please contact the Office of Public
        Affairs.
      </p>
      <p>Congressional Assignments:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/2024.02.28+House+Assignments+118.pdf">
            LSA Congressional Assignments - 118th Congress - House of Representatives (PDF)
          </a>{' '}
          - <em>updated February 2024</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/2024.06.12+Senate+Assignments+118.pdf">
            LSA Congressional Assignments - 118th Congress - Senate (PDF)
          </a>{' '}
          - <em>updated June 2024</em>
        </li>
      </ul>
      <p>Instructions and Guidelines:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20230106-Message-to-LSAs-for-the-118th-Congress.pdf">
            Message to Assigned LSAs-118th Congress (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Message-to-Assigned-LSAs-117th-Congress.pdf">
            Message to Assigned LSAs-117th Congress (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20230320-Background-Info-and-Strategic-Considerations.pdf">
            Background Info and Strategic Considerations (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Checklist-for-LSAs-Appointing-Delegations.pdf">
            Checklist for LSAs Appointing Delegations (PDF)
          </a>{' '}
          - <em>updated November 2023</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20230713-Guidelines-for-Delegations.pdf">
            Guidelines for Delegations (PDF)
          </a>{' '}
          - <em>updated July 2023</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Checklist-for-Delegations.pdf">Checklist for Delegations (PDF)</a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/FAQs-for-LSAs-and-Delegations.pdf">
            FAQs for LSAs and Delegations (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20230822-Assembly-Congressional-Assignment-Training.pptx">
            Assembly Congressional Assignment Training (PPTX)
          </a>{' '}
          - <em>updated August 22, 2023</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20230822-Delegation-Congressional-Assignment-Training.pptx">
            Delegation Congressional Assignment Training (PPTX)
          </a>{' '}
          - <em>updated August 22, 2023</em>
        </li>
      </ul>
      <p>Supporting Materials:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Status+of+Bahais+in+Iran+Fact+Sheet+2024.05.06+.pdf">
            Status of the Bahá’ís in Iran Fact Sheet (PDF)
          </a>{' '}
          - <em>updated May 6, 2024</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/2024.05.01+Informational+Resources.pdf">
            Informational Resources (PDF)
          </a>{' '}
          - <em>updated May 1, 2024</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20230922-Summary-and-Table-of-Congressional-Resolutions.pdf">
            Summary and Table of Congressional Resolutions (PDF)
          </a>{' '}
          - <em>updated September 22, 2023</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Forging-a-Path-to-racial-justice_OPA-version.pdf">
            Forging a Path to Racial Justice – NSA Statement to the American People on the Elimination of Racial Prejudice (PDF)
          </a>{' '}
          - <em>updated December 2023</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Talking-Points-on-Race.pdf">Talking Points on Race (PDF)</a>
        </li>
      </ul>
      <p>Reflection Seminars</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Congressional+Outreach+Webinar+handout.pdf">
            Winter 2024 Congressional Outreach Initiative Reflection Seminar slides
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=cat76MnbWUI" target="_blank" rel="noopener noreferrer">
            Northeast Region Seminar on 2/17/2024
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=rbXL_KEPh6E" target="_blank" rel="noopener noreferrer">
            South Central and Southeast Regions Seminar on 2/17/2024
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=uU8BzolgfvM" target="_blank" rel="noopener noreferrer">
            Four Corners, Northern Plains, Northwest and Appalachia Regions Seminar on 2/18/2024
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=WRWuGFNW7wQ" target="_blank" rel="noopener noreferrer">
            Midwest, Heartland and Prairie States Regions Seminar on 2/18/2024
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=bTKivveK9mw" target="_blank" rel="noopener noreferrer">
            Florida and Atlantic Regions Seminar on 2/24/2024
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=CVLRoXZPxqA" target="_blank" rel="noopener noreferrer">
            California Region Seminar on 2/24/2024
          </a>
        </li>
      </ul>
      <p>Letter and Press Release Templates:</p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Template-Thank-You+Letter-Cosponsorship.docx">
            Template Thank-You Letter - Cosponsorship (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Template-Thank-You-Letter-Visit.docx">
            Template Thank-You Letter - Visit (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Template-Press-Release-Cosponsorship.docx">
            Template Press Release - Cosponsorship (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Sample-Letter-to-LSA-Group-Asking-for-Delegation-Recommendations.docx">
            Sample Letter to LSA-Group Asking for Delegation Recommendations (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Sample-Letter-LSA-Outside-District-to-LSA-Grp-Asking-for-Delegation-Recommendations.docx">
            Sample Letter LSA Outside District to LSA-Grp Asking for Delegation Recommendations (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Sample-Letter-Invitation-to-Serve-on-Delegation.docx">
            Sample Letter Invitation to Serve on Delegation (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Sample-Letter-from-LSA-to-potential-delegation-leader.docx">
            Sample Letter from LSA to Potential Delegation Leader (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Invitation-to-Serve-as-Delegation-Leader.docx">
            Sample Invitation to Serve as Delegation Leader (DOCX)
          </a>
        </li>
      </ul>
      <p>Reporting:</p>
      <p>
        To keep the senior institutions of the Faith informed, it is necessary for the Office of Public Affairs (OPA) to gather
        regular and measurable data about congressional outreach efforts. Assemblies with Congressional assignments should now use
        the fillable PDF forms below to submit reports.
      </p>
      <p>
        The Assembly Status Report is about Assembly processes and efforts to form and supervise delegations. Send Assembly Status
        Reports to OPA about every four months until the delegations for all congressional assignments, should you have more than
        one, have been appointed. After that, send Assembly Status Reports once a year to confirm that the Assembly has completed
        its annual training, is following the Assembly checklist, providing guidance to the delegation(s), collecting and
        submitting reports in a timely manner, and otherwise providing first-level supervision to the delegation(s).
      </p>
      <p>
        Send Delegation Status Reports to OPA at least three times a year or whenever anything significant occurs. Report visits
        to congressional offices using the Delegation Visit Report. Either Assemblies or delegation leaders may send delegation
        status and visit reports. Delegation leaders who send reports to OPA directly should copy the Assembly to keep it
        informed. Download the reports below and save them to your computer as templates. Each time you prepare a new report,
        re-save it with a suitable filename that contains the date and some unique identifier (such as Assembly, state, district,
        or legislator name). Fill in the blanks and re-save it. Please answer all the questions. Then, attach the report to an
        email and send it to the Office of Public Affairs at <a href="mailto:usnsa-opa@usbnc.org">usnsa-opa@usbnc.org</a>. We are
        looking at ways to further automate the reporting process in the future, but for now, please follow this process.
      </p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Assembly+Status+Report+Template.pdf">
            Assembly Status Report Template (PDF)
          </a>{' '}
          - <em>updated January 2024</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Delegation+Status+Report+Template.pdf">
            Delegation Status Report Template (PDF)
          </a>{' '}
          - <em>updated January 2024</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/Delegation-Visit-Report-Template.pdf">
            Delegation Visit Report Template (PDF)
          </a>{' '}
          - <em>updated September 2023</em>
        </li>
      </ul>
      <hr />
      <h3>Institutional Guidance</h3>
      <p>
        The guidance provided on this page is a selection of letters from senior institutions that pertain to public
        affairs-related themes. For general guidance, please view the more comprehensive collection of messages from the Universal
        House of Justice and the National Spiritual Assembly in the <a href="../guidance">National Center’s Guidance Library</a>.
      </p>
      <h4>General Public Affairs Guidance</h4>
      <h6>Universal House of Justice</h6>
      <ul>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/uhj/2017/20170427_UHJ-guidance-on-social-action-and-public-discourse.pdf">
            Letter to an individual regarding participation in social action and the discourses of society (PDF)
          </a>{' '}
          - <em>updated April 27, 2017</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/uhj/2013/1126-persian-email-guidance/1126-persian-email-guidance-march_English.pdf">
            Letter to the Bahá’ís of Iran regarding political activity (PDF)
          </a>{' '}
          - <em>updated March 2, 2013</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/11262012-UHJ-Letter-to-all-NSAs-re-Social-Action-and-Attached-OSED-Statement-on-Social-Action.pdf">
            Letter to all National Spiritual Assemblies regarding social action & attached OSED statement on social action (PDF)
          </a>{' '}
          - <em>updated November 26, 2012</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/09202011-UHJ-Letter-to-Individual-re-Individual-Initiative-in-External-Affairs-Work.pdf">
            Letter to an individual regarding individual initiative in external affairs work (PDF)
          </a>{' '}
          - <em>updated September 20, 2011</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/01042009-UHJ-Letter-to-NSA-of-Australia-re-Yerrinbool-Bahai-School.pdf">
            Letter to the National Spiritual Assembly of Bahá’ís of Australia regarding participation in the discourses of society
            (PDF)
          </a>{' '}
          - <em>updated January 4, 2009</em>
        </li>
      </ul>
      <h6>National Spiritual Assembly of the Bahá’ís of the United States</h6>
      <ul>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2017/20170225_nsa_america-five-year-plan.pdf">
            Letter to the American Bahá’í Community regarding American and the Five-Year Plan (PDF)
          </a>{' '}
          - <em>updated February 25, 2017</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/01172017-NSA-to-American-Bahai-Community-re-Womens-March-with-attachments.pdf">
            Letter to the American Bahá’í community regarding the Women’s March (PDF)
          </a>{' '}
          - <em>updated January 17, 2017</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2012/0914-non-involvement-in-partisan-politics/0914-non-involvement-in-partisan-politics_english.pdf">
            Letter to the American Bahá’í community regarding non-participation in partisan politics (PDF)
          </a>{' '}
          - <em>updated September 14, 2012</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2008/0206-noninvolvement-in-political-affairs/0206-noninvolvement-in-political-affairs_English.pdf">
            Letter to the American Bahá’í community regarding political non-involvement (PDF)
          </a>{' '}
          - <em>updated February 6, 2008</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20200619-NSA-Statement-to-the-American-People-on-the-Elimination-of-Racial-Prejudice.pdf">
            NSA Statement to the American People on the Elimination of Racial Prejudice (PDF)
          </a>{' '}
          - <em>updated June 19, 2020</em>
        </li>
      </ul>
      <h4>Advancement of Women</h4>
      <h6>Universal House of Justice</h6>
      <ul>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/uhj/2008/0620-to-believers-in-iran/0620-to-believers-in-iran_English.pdf">
            Letter to the Bahá’ís of Iran regarding the equality of women and men (PDF)
          </a>{' '}
          - <em>updated June 20, 2008</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/uhj/1993/0124-domestic-violence/0124-domestic-violence_English.pdf">
            Letter to an individual regarding the abuse of women and children (PDF)
          </a>{' '}
          - <em>updated January 24, 1993</em>
        </li>
      </ul>
      <h4>Bahá’ís in Iran</h4>
      <h6>Universal House of Justice</h6>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/01252012-UHJ-Letter-to-Individual-re-Individual-Efforts-to-Defend-the-Bahais-of-Iran.pdf">
            Letter to an individual regarding individual efforts to defend the Bahá’ís of Iran (PDF)
          </a>{' '}
          - <em>updated January 25, 2012</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/uhj/2009/0623-to-iranian-bahais/0623-to-iranian-bahais_English.pdf">
            Letter to the Bahá’ís of Iran regarding political turmoil and civil unrest (PDF)
          </a>{' '}
          - <em>updated June 23, 2009</em>
        </li>
      </ul>
      <h6>Bahá’í International Community</h6>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/08262011-BIC-Open-Letter-to-Minister-of-Science-Research-and-Tech-re-BIHE-English.pdf">
            Open Letter to the Minister of Science, Research and Technology of Iran regarding the Bahá’í Institute for Higher
            Education (PDF)
          </a>{' '}
          - <em>August 26, 2011</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/12072010-BIC-Open-Letter-to-Head-of-Judiciary-re-Yaran-English.pdf">
            Open Letter to head of the Iranian judiciary regarding the Yaran (PDF)
          </a>{' '}
          - <em>updated December 7, 2010</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/03042009-BIC-Letter-to-Prosecutor-General-of-Iran-re-Yaran-English.pdf">
            Open Letter to the Prosecutor General of Iran regarding the Yaran (PDF)
          </a>{' '}
          - <em>updated March 4, 2009</em>
        </li>
      </ul>
      <h6>National Spiritual Assembly of the Bahá’ís of the United States</h6>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/06242009-NSA-Letter-re-Participation-on-Internet-re-Iran.pdf">
            Letter to American Bahá’í community regarding participation on the internet about Iran (PDF)
          </a>{' '}
          - <em>updated June 24, 2009</em>
        </li>
        <li>
          <a href="https://bahai-messages.s3.amazonaws.com/nsa/2009/0618-participation-in-demonstrations/0618-participation-in-demonstrations_English.pdf">
            Letter to American Bahá’í community regarding participation in demonstrations (PDF)
          </a>{' '}
          - <em>updated June 18, 2009</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2007/1127-dissemination-of-news-in-iran/1127-dissemination-of-news-in-iran_English.pdf">
            Letter from the Office of External Affairs to the American Bahá’í community regarding dissemination of news about
            believers in Iran (PDF)
          </a>{' '}
          - <em>updated November 27, 2007</em>
        </li>
      </ul>
      <p>
        For inquiries regarding travel to Iran, please contact the Office of Public Affairs at{' '}
        <a href="mailto:BahaisUS@usbnc.org">BahaisUS@usbnc.org</a> or 202-833-8990.
      </p>
      <h4>Other Issues</h4>
      <h6>Universal House of Justice</h6>
      <ul>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/uhj/2011/0410-concerning-racial-prejudice-and-divine-plan/0410-concerning-racial-prejudice-and-divine-plan_English.pdf">
            Letter to Individual on Race & the Divine Plan (PDF)
          </a>{' '}
          - April 10, 2011
        </li>
      </ul>
      <h6>National Spiritual Assembly of the Bahá’ís of the United States</h6>
      <ul>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/feast/2013/0604-feast-nur/0604-feast-nur_English.pdf">
            Feast Letter regarding racial justice and unity efforts in the United States (PDF)
          </a>{' '}
          - <em>updated June 4, 2012</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2011/0414-letter-from-bahais-of-egypt/0414-letter-from-bahais-of-egypt_English.pdf">
            Letter to all Registered Groups and Local Spiritual Assemblies regarding Open Letter from the Bahá’ís of Egypt (PDF)
          </a>{' '}
          - <em>updated April 14, 2011</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2011/0103-uhj-on-homosexuality/0103-uhj-on-homosexuality_English.pdf">
            Letter to the American Bahá’í community sharing guidance from the Universal House of Justice regarding homosexuality
            and civil rights (PDF)
          </a>{' '}
          - <em>updated January 3, 2011</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2009/0216-presidential-election-significance/0216-presidential-election-significance_English.pdf">
            Letter to the American Bahá’í community regarding the 2008 Presidential Election (PDF)
          </a>{' '}
          - <em>updated February 16, 2009</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2005/0601-interfaith-alliance/0601-interfaith-alliance_English.pdf">
            Letter from the Office of External Affairs to the American Bahá’í community regarding the Interfaith Alliance (PDF)
          </a>{' '}
          - <em>updated June 1, 2005</em>
        </li>
      </ul>
      <h4>Sustainable Development</h4>
      <h6>National Spiritual Assembly of the Bahá’ís of the United States</h6>
      <ul>
        <li>
          <a href="https://bahai-messages.s3.amazonaws.com/nsa/2015/nsa-interfaith-climate-change-150122-english/nsa-interfaith-climate-change-150122-english.pdf">
            Letter to the American Bahá’í Community regarding National &quot;Preach-In on Climate Change&quot; (PDF)
          </a>{' '}
          - <em>updated January 22, 2015</em>
        </li>
        <li>
          <a href="https://bahai-messages.s3.amazonaws.com/nsa/2014/0116-national-preach-in/0116-national-preach-in_English.pdf">
            Letter to the American Bahá’í community regarding National &quot;Preach-in on Climate Change&quot; Day (PDF)
          </a>{' '}
          - <em>updated January 16, 2014</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/01252013-NSA-to-American-Bahai-Community-re-Global-Climate-Change.pdf">
            Letter to the American Bahá’í community regarding global climate change (PDF)
          </a>{' '}
          - <em>updated January 25, 2013</em>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/20120329-American-Bahai-Community-Earth-Week-Activities-and-Climate-Change.pdf">
            Letter to the American Bahá’í Community regarding Earth Week activities and climate change (PDF)
          </a>{' '}
          - <em>updated March 29, 2012</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2011/0119-global-warming-preach-in/0119-global-warming-preach-in_English.pdf">
            Letter to the American Bahá’í community regarding the Global Warming Preach-in (PDF)
          </a>{' '}
          - <em>updated January 19, 2011</em>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-messages/nsa/2010/0225-environment-and-climate-change/0225-environment-and-climate-change_English.pdf">
            Letter to the American Bahá’í community regarding the environment and climate change (PDF)
          </a>{' '}
          - <em>updated February 25, 2010</em>
        </li>
      </ul>
      <h6>U.S. Baha’i Office of Public Affairs</h6>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/OPA/07252014-OPA-ltr-to-LSAs.pdf">
            Letter to the American Bahá’í community regarding the People’s Climate March in New York (PDF)
          </a>{' '}
          - <em>updated July 25, 2014</em>
        </li>
      </ul>
    </div>
  );
}

export default PublicAffairs;
