import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { withStyles, FormControl, Select, InputLabel, MenuItem, TextField, OutlinedInput } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import PasswordViewControl from '../../Utilities/PasswordViewControl';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});
function CreateAccount(props: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [prevValue1, setPrevValue1] = useState(undefined);
  const [prevValue2, setPrevValue2] = useState(undefined);
  const [prevValue3, setPrevValue3] = useState(undefined);
  const [optionlist1, setOptionlist1] = useState([] as any[]);
  const [optionlist2, setOptionlist2] = useState([] as any[]);
  const [optionlist3, setOptionlist3] = useState([] as any[]);
  const isVisible = props.success ? false : true;

  useEffect(() => {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }, [props.displayMessage]);

  useEffect(() => {
    if (!props.questiondata) {
      return;
    }

    const optionList = (props.questiondata as any[]).map((value, index) => (
      <MenuItem key={index} value={value.key}>
        {value.question}
      </MenuItem>
    ));

    setOptionlist1(optionList);
    setOptionlist2(optionList);
    setOptionlist3(optionList);
  }, [props.questiondata]);

  function filterQuestions(e: any) {
    if (e.target.name === 'questionOne') {
      if (prevValue1 !== undefined && prevValue1 !== e.target.value) {
        let selectedquestion = '';
        for (let i = 0; i < props.questiondata.length; i++)
          if (props.questiondata[i].key === prevValue1) {
            selectedquestion = props.questiondata[i].question;
            setOptionlist2(
              (optionlist2 as any).push(
                <MenuItem key={i} data-cy={'listitem' + (i + 1)} value={prevValue1}>
                  {selectedquestion}
                </MenuItem>
              )
            );
            setOptionlist3(
              (optionlist3 as any).push(
                <MenuItem key={i} data-cy={'listitem' + (i + 1)} value={prevValue1}>
                  {selectedquestion}
                </MenuItem>
              )
            );
          }
      }
      setOptionlist2(
        optionlist2.filter((item) => {
          return item.props.value !== e.target.value;
        })
      );
      setOptionlist3(
        optionlist3.filter((item) => {
          return item.props.value !== e.target.value;
        })
      );
      setPrevValue1(e.target.value);
    }
    if (e.target.name === 'questionTwo') {
      if (prevValue2 !== undefined && prevValue2 !== e.target.value) {
        let selectedquestion = '';
        for (let i = 0; i < props.questiondata.length; i++)
          if (props.questiondata[i].key === prevValue2) {
            selectedquestion = props.questiondata[i].question;
            setOptionlist1(
              (optionlist1 as any).push(
                <MenuItem key={i} data-cy={'listitem' + (i + 1)} value={prevValue2}>
                  {selectedquestion}
                </MenuItem>
              )
            );
            setOptionlist3(
              (optionlist3 as any).push(
                <MenuItem key={i} data-cy={'listitem' + (i + 1)} value={prevValue2}>
                  {selectedquestion}
                </MenuItem>
              )
            );
          }
      }
      setOptionlist1(
        optionlist1.filter((item) => {
          return item.props.value !== e.target.value;
        })
      );
      setOptionlist3(
        optionlist3.filter((item) => {
          return item.props.value !== e.target.value;
        })
      );
      setPrevValue2(e.target.value);
    }
    if (e.target.name === 'questionThree') {
      if (prevValue3 !== undefined && prevValue3 !== e.target.value) {
        let selectedquestion = '';
        for (let i = 0; i < props.questiondata.length; i++)
          if (props.questiondata[i].key === prevValue3) {
            selectedquestion = props.questiondata[i].question;
            setOptionlist1(
              (optionlist1 as any).push(
                <MenuItem key={i} data-cy={'listitem' + (i + 1)} value={prevValue3}>
                  {selectedquestion}
                </MenuItem>
              )
            );
            setOptionlist2(
              (optionlist2 as any).push(
                <MenuItem key={i} data-cy={'listitem' + (i + 1)} value={prevValue3}>
                  {selectedquestion}
                </MenuItem>
              )
            );
          }
      }
      setOptionlist1(
        optionlist1.filter((item) => {
          return item.props.value !== e.target.value;
        })
      );
      setOptionlist2(
        optionlist2.filter((item) => {
          return item.props.value !== e.target.value;
        })
      );
      setPrevValue3(e.target.value);
    }
  }

  return (
    <Row around="xs" className="faded-background-medium">
      <Col xs={12} className="form-style wide-form">
        <form onSubmit={props.handleSubmit}>
          <h3>Create an Account</h3>
          {props.displayMessage}
          <span className={isVisible ? 'show' : 'hide'}>
            <TextField
              required
              inputProps={{ 'data-cy': 'email' }}
              id="email"
              label="Email"
              aria-label="Email"
              type="text"
              name={props.emailname}
              value={props.emailValue}
              onChange={props.handleChange}
              margin="normal"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              required
              inputProps={{ 'data-cy': 'confirmEmail' }}
              id="confirmEmail"
              label="Confirm Email"
              aria-label="Confirm Email"
              type="text"
              name={props.confirmEmailname}
              value={props.confirmEmailValue}
              onChange={props.handleChange}
              margin="normal"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <ul className="plain-list no-margin large-top-padding">
              <li>Passwords must be at least 8 characters long and contain at least 3 of the following:</li>
              <li>One lower case letter</li>
              <li>One UPPER case letter</li>
              <li>One number</li>
              <li>One special character such as !@$%^*</li>
              <li>It will NOT accept the following 3 characters # &amp; &lt;</li>
            </ul>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" className="top-margin">
                Password
              </InputLabel>
              <OutlinedInput
                required
                type={showPassword ? 'text' : 'password'}
                value={props.passwordValue}
                name={props.passwordname}
                data-cy="passwordField"
                className="top-margin"
                onChange={props.handleChange}
                endAdornment={
                  <PasswordViewControl showPassword={showPassword} setShowPassword={setShowPassword} datacy="passwordEye" />
                }
                labelWidth={70}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" className="top-margin">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                required
                type={showConfirmPassword ? 'text' : 'password'}
                value={props.confirmPasswordValue}
                name={props.confirmPasswordname}
                className="top-margin"
                data-cy="confirmPasswordField"
                onChange={props.handleChange}
                endAdornment={
                  <PasswordViewControl
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                    datacy="confirmPasswordEye"
                  />
                }
                labelWidth={132}
              />
            </FormControl>
            <div className="large-top-padding start">
              <p>We recommend choosing different answers for your security questions.</p>
              <FormControl variant="outlined" margin="dense" className="medium-dropdown full-width">
                <InputLabel id="q1">Question 1</InputLabel>
                <Select
                  value={props.questionOneValue ?? ''}
                  onChange={(e) => {
                    props.handleChange(e);
                    filterQuestions(e);
                  }}
                  data-cy="question1"
                  labelId="q1"
                  label="Question 1"
                  inputProps={{
                    'data-cy': 'inputOne',
                    name: props.questionOneName,
                  }}
                >
                  <MenuItem value="">
                    <em>Please select your question</em>
                  </MenuItem>
                  {optionlist1}
                </Select>
              </FormControl>
              <TextField
                required
                inputProps={{ 'data-cy': 'questionOneAns' }}
                id="questionOneAns"
                type="text"
                name={props.questionOneAnsName}
                label="Answer 1"
                value={props.questionOneAns}
                onChange={props.handleChange}
                margin="normal"
                fullWidth={true}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <FormControl variant="outlined" margin="dense" className="medium-dropdown full-width">
                <InputLabel id="q2">Question 2</InputLabel>
                <Select
                  value={props.questionTwoValue ?? ''}
                  onChange={(e) => {
                    props.handleChange(e);
                    filterQuestions(e);
                  }}
                  data-cy="question2"
                  labelId="q2"
                  label="Question 2"
                  inputProps={{ name: props.questionTwoName }}
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>Please select your question</em>
                  </MenuItem>
                  {optionlist2}
                </Select>
              </FormControl>
              <TextField
                required
                inputProps={{ 'data-cy': 'questionTwoAns' }}
                id="questionTwoAns"
                type="text"
                name={props.questionTwoAnsName}
                label="Answer 2"
                value={props.questionTwoAns}
                onChange={props.handleChange}
                margin="normal"
                fullWidth={true}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <FormControl variant="outlined" margin="dense" className="medium-dropdown full-width">
                <InputLabel id="q3">Question 3</InputLabel>
                <Select
                  value={props.questionThreeValue ?? ''}
                  onChange={(e) => {
                    props.handleChange(e);
                    filterQuestions(e);
                  }}
                  data-cy="question3"
                  labelId="q3"
                  label="Question 3"
                  inputProps={{ name: props.questionThreeName }}
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>Please select your question</em>
                  </MenuItem>
                  {optionlist3}
                </Select>
              </FormControl>
              <TextField
                required
                inputProps={{ 'data-cy': 'questionThreeAns' }}
                id="questionThreeAns"
                type="text"
                name={props.questionThreeAnsName}
                label="Answer 3"
                value={props.questionThreeAns}
                onChange={props.handleChange}
                margin="normal"
                fullWidth={true}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <Row around="xs" className="top-margin row-reverse">
                <Col>
                  <input
                    data-cy="createaccSubmitButton"
                    type="submit"
                    value="Submit"
                    id="createaccSubmitButton"
                    className="primary-button"
                  />
                </Col>
                <Col>
                  {props?.history?.goBack ? (
                    <>
                      <button className="secondary-button" onClick={props.cancelHandler}>
                        Cancel
                      </button>
                      <button className="secondary-button small-left-margin" data-cy="backButton" onClick={props.history.goBack}>
                        Back
                      </button>
                    </>
                  ) : (
                    <Link data-cy="cancel-button" className="secondary-button" to="/login">
                      Cancel
                    </Link>
                  )}
                </Col>
              </Row>
            </div>
          </span>
        </form>
        <span className={isVisible ? 'hide' : 'show'}>
          <p>
            <NavLink to="/login">&lt; back to login</NavLink>
          </p>
        </span>
      </Col>
    </Row>
  );
}
export default withStyles(styles, { withTheme: true })(CreateAccount);
