import React from 'react';
import { Link } from 'react-router-dom';

function NatConResources() {
  return (
    <div>
      <h1>National Convention: Resources and Guidance</h1>
      <br />
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/UHJ-Compilation-on-National-Convention-Dec92.pdf">
            “The National Convention” - A compilation from the Research Dept. (UHJ, 1992) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/UHJ-Statement-on-National-Convention-Dec92.pdf">
            “The National Convention” - A statement from the Research Dept. (UHJ, 1992) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/NatCon/2023/Complete+Article+VIII+of+Constitution+and+By-Laws+of+US+NSA.pdf">
            Article VIII of the Constitution and By-Laws of the US NSA (PDF)
          </a>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/March-25-2007-Electoral-Procedure.pdf">
            March 25, 2007 Letter from UHJ on the Bahá’í electoral process (PDF)
          </a>
        </li>
        <li>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/May-16-2013-UHJ-to-delegates.pdf">
            May 16, 2013 Letter from UHJ on the sacred nature of National Convention (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/20160804-UHJ-to-all-NSAs-on-the-Allocation-of-Delegates.pdf">
            August 4, 2016 Letter from UHJ on the allocation of delegates (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/the-sacred-task-of-voting-in-a-bahai-election.pdf">
            Sacred Task of Voting: Selections from the writings of Shoghi Effendi (PDF)
          </a>
        </li>
        <li>
          <Link to="/guidance/uhj/baha-i-world-center-publishes-new-compilation-on-the-institution-of-the-national-spiritual-assembly-2023/baha-i-world-center-publishes-new-compilation-on-the-institution-of-the-national-spiritual-assembly-2023">
            Compilation on the National Spiritual Assembly (PDF)
          </Link>
        </li>
        <li>
          <Link to="/guidance/uhj/supreme-body-shares-historical-review-and-reflections-on-the-future-2023/supreme-body-shares-historical-review-and-reflections-on-the-future-2023">
            Supreme Body Shares Historical Review and Reflections on the Future (PDF)
          </Link>
        </li>
        <li>
          <Link to="/news/natcon-2023">
            National Convention 2023: Coverage from <em>The American Bahá’í</em>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default NatConResources;
