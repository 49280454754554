import React, { Component, JSX } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { withStyles, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import createAppToken from './Utilities/AppTokenEndpoint';
import ForgotPasswordEndpoint from './Utilities/ForgotPasswordEndpoint';
import withContext from '../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { CircularProgress } from '@material-ui/core';
import SafeStringify from '../Utilities/SafeStringify';
import { History, Location } from 'history';
import { match, StaticContext } from 'react-router';
import { Theme } from '@material-ui/core/styles/createTheme';
import { ContextProviderInterface } from '../ContextAPI/ContextProviderInterface';
import ReCAPTCHA from 'react-google-recaptcha';
import FeatureKey from '../FeatureFlag/FeatureKey';
import { OptimizelyFeature, WithOptimizelyProps, withOptimizely } from '@optimizely/react-sdk';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});
export interface ForgotPasswordProps extends WithOptimizelyProps {
  location: Location;
  history: History;
  match: match;
  staticContext: StaticContext;
  theme: Theme;
  classes: any;
  context: ContextProviderInterface;
  bypassRecaptcha: boolean;
}
export interface ForgorPasswordState {
  bahaiId: string;
  email: string;
  displayMessage: JSX.Element;
  success: boolean;
  processing: boolean;
  dontHaveAccessToEmail: boolean;
  newemail: string;
  lastname: string;
  googleReCaptchaResponse: string;
  bypassRecaptcha: boolean;
  [x: number]: any;
}
class ForgotPassword extends Component<ForgotPasswordProps & WithOptimizelyProps, ForgorPasswordState> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      bahaiId: '',
      email: '',
      displayMessage: <></>,
      success: false,
      processing: false,
      dontHaveAccessToEmail: false,
      newemail: '',
      lastname: '',
      googleReCaptchaResponse: '',
      bypassRecaptcha: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);

    this.props.context
      .featureFlagIsEnabledFunction(FeatureKey.RecaptchaEnabledForAuth, this.props.optimizely)
      .then((result: boolean) => {
        if (result) {
          this.setState({
            bypassRecaptcha: false,
          });
        }
      });
  }

  handleRecaptcha(token: string | null) {
    this.setState({
      googleReCaptchaResponse: token || '',
      displayMessage: <></>,
    });
  }

  handleChange(e: any) {
    const newState = {
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    };
    this.setState(newState, function () {});
  }
  handleSubmit(e: any) {
    e.preventDefault();

    if (this.state.processing) {
      return;
    }

    const recaptchaCode = process.env.REACT_APP_RECAPTCHA_CODE;
    if (!this.state.bypassRecaptcha && recaptchaCode && !this.state.googleReCaptchaResponse) {
      this.setState({
        processing: false,
        displayMessage: (
          <p className="error-message" data-cy="error-message">
            Please fill Recaptcha to continue.
          </p>
        ),
      });
      return;
    }

    this.setState({ processing: true }, () =>
      createAppToken()
        .then((res) => {
          ForgotPasswordEndpoint(
            res.data,
            this.state.bahaiId,
            this.state.email,
            this.state.dontHaveAccessToEmail,
            this.state.newemail,
            this.state.lastname,
            this.state.googleReCaptchaResponse
          )
            .then((res) => {
              if (res.data === 'Account needs to be created.') {
                this.props.context.setCreateBosErrorDisplayMessage(
                  'To increase security, this website now requires that every user create a Bahá’í Online Services Account'
                );
                this.props.context.setCreateBosPrefillBahaiId(this.state.bahaiId);
                this.props.history.push('/createboswizard/step1');
              } else {
                this.setState({
                  processing: false,
                  displayMessage: (
                    <p className="success-message" data-cy="success-message">
                      {res.data ? SafeStringify(res.data) : null}
                    </p>
                  ),
                  success: true,
                });
              }
            })
            .catch((err) => {
              this.setState({
                processing: false,
                displayMessage: (
                  <p className="error-message" data-cy="error-message">
                    {err.message ? SafeStringify(err.message) : null}
                  </p>
                ),
              });
            });
        })
        .catch((err) => {
          this.setState({
            processing: false,
            displayMessage: <p className="error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          });
        })
    );
  }
  render() {
    const isVisible = this.state.success ? false : true;
    const recaptchaCode = process.env.REACT_APP_RECAPTCHA_CODE;

    return (
      <div className="center">
        <div data-cy="logo" className="starlogo star-margin" />
        <h3 data-cy="logotext" className="logo-text">
          Bahá’ís of the United States
        </h3>
        <form autoComplete="off" className="center form-style top-margin" onSubmit={this.handleSubmit}>
          <h4>Reset your Password</h4>
          {this.state.displayMessage}
          <span className={isVisible ? 'show' : 'hide'}>
            <TextField
              id="bahaiid-input"
              inputProps={{ 'data-cy': 'bahaiid' }}
              type="text"
              name="bahaiId"
              label="Bahá’í ID *"
              aria-label="Bahá’í ID"
              autoComplete="Bahá’í ID"
              margin="normal"
              value={this.state.bahaiId}
              onChange={this.handleChange}
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              id="email-input"
              inputProps={{ 'data-cy': 'email' }}
              type="text"
              name="email"
              label="Email *"
              aria-label="Email"
              autoComplete={this.state.dontHaveAccessToEmail ? undefined : 'Email'}
              margin="normal"
              value={this.state.dontHaveAccessToEmail ? '' : this.state.email}
              onChange={this.handleChange}
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              disabled={this.state.dontHaveAccessToEmail}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="dontHaveAccessToEmail"
                  color="default"
                  checked={this.state.dontHaveAccessToEmail}
                  onChange={this.handleChange}
                  inputProps={{ 'data-cy': 'dontHaveAccessToEmail' } as any}
                />
              }
              label="I don’t have access to the email currently associated with my account"
            />
            {this.state.dontHaveAccessToEmail ? (
              <div>
                <TextField
                  id="new-email-input"
                  inputProps={{ 'data-cy': 'newemail' }}
                  type="text"
                  name="newemail"
                  label="New Email *"
                  aria-label="New Email"
                  autoComplete="Email"
                  margin="normal"
                  value={this.state.newemail}
                  onChange={this.handleChange}
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="last-name-input"
                  inputProps={{ 'data-cy': 'lastname' }}
                  type="text"
                  name="lastname"
                  label="Last Name *"
                  aria-label="Last Name"
                  autoComplete="Last Name"
                  margin="normal"
                  value={this.state.lastname}
                  onChange={this.handleChange}
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            ) : null}
            <OptimizelyFeature feature={FeatureKey.RecaptchaEnabledForAuth}>
              {(isEnabled) =>
                isEnabled ? (
                  <Row center="xs" className="small-bottom-padding">
                    {recaptchaCode && <ReCAPTCHA size="compact" sitekey={recaptchaCode} onChange={this.handleRecaptcha} />}
                  </Row>
                ) : (
                  <></>
                )
              }
            </OptimizelyFeature>
            <Row around="xs" className="small-top-margin row-reverse">
              <Col>
                {this.state.processing ? (
                  <CircularProgress className="loading-animation" size={40} />
                ) : (
                  <input data-cy="submit" type="submit" value="Submit" className="primary-button" />
                )}
              </Col>
              <Col>
                <Link data-cy="cancel-button" className="secondary-button" to="/login">
                  Cancel
                </Link>
              </Col>
            </Row>
          </span>
          <span className={isVisible ? 'hide' : 'show'}>
            <p>
              <NavLink to="/login">&lt; back to login</NavLink>
            </p>
          </span>
        </form>
        <span className={isVisible ? 'show' : 'hide'}>
          <div className="faded-background-small">
            <p>
              New to Bahai.us? <NavLink to="/createboswizard/step1">Create an account</NavLink>
            </p>
          </div>
        </span>
      </div>
    );
  }
}

export default withRouter(withContext(withOptimizely(withStyles(styles, { withTheme: true })(ForgotPassword))));
