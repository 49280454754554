import React, { ChangeEventHandler, FormEventHandler, JSX, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { withStyles, FormControl, TextField, Select, MenuItem } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import OverseasQuestionsEndpoint, {
  OverseasValidationAllowedValues,
  OverseasValidationEndpointContent,
} from '../Utilities/OverseasQuestionsEndpoint';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';
import SortPositiveIdThenSequence from './SortPositiveIdThenSequence';
import SafeStringify from '../../Utilities/SafeStringify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ClassesSelectListStyle } from '../PasswordResetWithQuestions/ClassesSelectListStyle';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import { CreateBOSState } from '../CreateBOSWizard/CreateBOSBaseStep';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
  selectListStyle: {
    height: 50,
  },
});
interface QuestionInputFieldInterface {
  handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | CustomEventFunction;
  classes?: ClassesSelectListStyle;
  questionInfo: OverseasValidationEndpointContent;
  model?: CreateBOSState;
}
function QuestionInputField({ handleChange, questionInfo, classes, model }: QuestionInputFieldInterface) {
  const name = 'overseasAnswer_' + questionInfo.id;
  if (questionInfo.regex === '^\\d{4}-[01]\\d-\\d{2}$') {
    const dateFormat = 'YYYY-MM-DD';
    return (
      <DatePicker
        slotProps={{
          textField: {
            'aria-label': name,
            inputProps: { 'data-cy': name },
            id: name,
            name: name,
            variant: 'outlined',
            margin: 'normal',
          },
        }}
        onChange={(e: dayjs.Dayjs | null) => {
          let value = '';
          if (e) {
            if (e.toString().trim() !== 'Invalid Date') {
              value = e.format(dateFormat);
            }
          }
          if (e === null) {
            value = '';
          }
          const customDateEvent: CustomEvent = {
            target: {
              name: name,
              type: 'date',
              value,
            },
          };
          (handleChange as CustomEventFunction)(customDateEvent);
        }}
        value={model && model[name] ? dayjs(model[name], dateFormat) : null}
      />
    );
  }

  if (questionInfo.regex === '^\\d{4}$') {
    return (
      <TextField
        type="text"
        name={'overseasAnswer_' + questionInfo.id}
        onChange={handleChange}
        margin="normal"
        fullWidth={true}
        variant="outlined"
        inputProps={{
          'data-cy': 'overseasAnswer_' + questionInfo.id,
          pattern: questionInfo.regex,
          title: 'Must be four digits. e.g. 1988',
        }}
        InputLabelProps={{ shrink: true }}
        helperText="Must be four digits. e.g. 1988"
        value={model ? model[name] : ''}
      />
    );
  }

  if (questionInfo.allowedValues) {
    return (
      <Select
        name={'overseasAnswer_' + questionInfo.id}
        data-cy={'overseasAnswer_' + questionInfo.id}
        className={classes?.selectListStyle}
        onChange={handleChange as (event: Object, change?: ReactNode) => void}
        variant="outlined"
        defaultValue={model && model[name] ? model[name] : ''}
      >
        <MenuItem value=""></MenuItem>
        {questionInfo.allowedValues?.map((item: OverseasValidationAllowedValues, key: number) => (
          <MenuItem key={key} value={item.value}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <TextField
      type={questionInfo.text.match(/\bemail\b|\be-mail\b/i) ? 'email' : 'text'}
      name={'overseasAnswer_' + questionInfo.id}
      onChange={handleChange}
      margin="normal"
      fullWidth={true}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{
        'data-cy': 'overseasAnswer_' + questionInfo.id,
        pattern: questionInfo.regex,
        title: questionInfo.text.match(/\bemail\b|\be-mail\b/i) ? 'Must be a valid email address.' : undefined,
      }}
      value={model ? model[name] ?? '' : ''}
    />
  );
}

async function displayAdditionalQuestions(
  createAppTokenPromise: Function,
  setQuestions: React.Dispatch<React.SetStateAction<QuestionOverseas | undefined>>,
  setLocalDisplayMessage: React.Dispatch<React.SetStateAction<JSX.Element>>,
  setShowCircularProgress: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorOccured: React.Dispatch<React.SetStateAction<boolean>>,
  temporaryCode?: string
) {
  try {
    var token = temporaryCode ? '' : (await createAppTokenPromise()).data;
    OverseasQuestionsEndpoint(token, temporaryCode)
      .then(async (res) => {
        setQuestions({
          overseasQuestionData: res.contents,
          overseasRequiredCorrectAnswerCount: res.requiredCorrectAnswerCount,
          requiredCorrectSecurityAnswerCount: res.requiredCorrectSecurityAnswerCount,
        });
        setLocalDisplayMessage(<></>);
        setShowCircularProgress(false);
      })
      .catch((err) => {
        if (err.status === 400 && err.message) {
          setLocalDisplayMessage(<p className="center error-message">{SafeStringify(err.message)}</p>);
        } else {
          console.error(err);
          setLocalDisplayMessage(<ErrorMessageToUser />);
        }
        setErrorOccured(true);
      });
  } catch (err) {
    console.error(err);
    setLocalDisplayMessage(<ErrorMessageToUser />);
    setErrorOccured(true);
  }
}
type CustomEvent = { target: { name: string; type?: string; value: string | number } };
type CustomEventFunction = (event: CustomEvent) => void;
interface OverseasValidationFormProps {
  model?: CreateBOSState;
  title: string;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  displayMessage: JSX.Element;
  handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | CustomEventFunction;
  createAppTokenPromise: Function;
  temporaryCode?: string;
  showCircularProgress: boolean;
  classes?: ClassesSelectListStyle;
  history?: RouterHistory;
  cancelHandler?: MouseEventHandler<HTMLButtonElement>;
}
interface QuestionOverseas {
  overseasQuestionData: OverseasValidationEndpointContent[] | null;
  overseasRequiredCorrectAnswerCount: number;
  requiredCorrectSecurityAnswerCount: number;
}
function OverseasValidationForm(props: OverseasValidationFormProps) {
  const [errorOccurred, setErrorOccured] = useState(false);
  const [questions, setQuestions] = useState<QuestionOverseas>();
  const [localDisplayMessage, setLocalDisplayMessage] = useState(<></>);
  const [localShowCircularProgress, setLocalShowCircularProgress] = useState(true);
  const { createAppTokenPromise, temporaryCode, displayMessage, showCircularProgress } = props;
  useEffect(() => {
    displayAdditionalQuestions(
      createAppTokenPromise,
      setQuestions,
      setLocalDisplayMessage,
      setLocalShowCircularProgress,
      setErrorOccured,
      temporaryCode
    );
  }, [createAppTokenPromise, temporaryCode]);

  const { handleChange } = props;
  const overseasRequiredCorrectAnswerCount = questions?.overseasRequiredCorrectAnswerCount;
  const requiredCorrectSecurityAnswerCount = questions?.requiredCorrectSecurityAnswerCount;
  const countOfSecurityQuestions = questions?.overseasQuestionData?.filter((questionInfo) => questionInfo.id > 0).length;

  useEffect(() => {
    if (overseasRequiredCorrectAnswerCount) {
      (handleChange as CustomEventFunction)({
        target: {
          name: 'overseasRequiredCorrectAnswerCount',
          value: overseasRequiredCorrectAnswerCount,
        },
      });
    }
    if (requiredCorrectSecurityAnswerCount) {
      (handleChange as CustomEventFunction)({
        target: {
          name: 'requiredCorrectSecurityAnswerCount',
          value: requiredCorrectSecurityAnswerCount,
        },
      });
    }
    if (countOfSecurityQuestions) {
      (handleChange as CustomEventFunction)({
        target: {
          name: 'countOfSecurityQuestions',
          value: countOfSecurityQuestions,
        },
      });
    }
  }, [handleChange, overseasRequiredCorrectAnswerCount, requiredCorrectSecurityAnswerCount, countOfSecurityQuestions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [displayMessage, localDisplayMessage, showCircularProgress]);
  if (errorOccurred) {
    return (
      <Row around="xs" className="faded-background-large">
        <Col xs={12} className="form-style wider">
          {localDisplayMessage}
          <Link data-cy="cancel-button" className="secondary-button" to="/forgot">
            Back
          </Link>
        </Col>
      </Row>
    );
  }

  let securityQuestionNote = '';
  if (requiredCorrectSecurityAnswerCount && requiredCorrectSecurityAnswerCount > 0) {
    questions?.overseasQuestionData?.sort(SortPositiveIdThenSequence);
    securityQuestionNote = ` (including ${requiredCorrectSecurityAnswerCount} from the first ${countOfSecurityQuestions} questions)`;
  }

  return (
    <Row around="xs" className="faded-background-large">
      <Col xs={12} className="form-style wider">
        {localShowCircularProgress ? (
          <CircularProgress data-cy="overseas-validation-circular-progress" className="loading-animation" size={40} />
        ) : (
          <div>
            {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
            <form onSubmit={props.handleSubmit}>
              <h4>{props.title}</h4>
              {displayMessage}
              {questions?.overseasRequiredCorrectAnswerCount ? (
                <p>
                  Please answer at least {questions.overseasRequiredCorrectAnswerCount} of the following questions
                  {securityQuestionNote}:
                </p>
              ) : null}
              {questions?.overseasQuestionData?.map((questionInfo: OverseasValidationEndpointContent, key: number) => (
                <Row key={key} className={questionInfo.id > 0 ? 'security-question-row' : ''}>
                  <Col sm={6} className="inline-vertical-center">
                    <p>{questionInfo.text}</p>
                  </Col>
                  <Col sm={6}>
                    <FormControl className="min-width-full">
                      <QuestionInputField
                        model={props.model}
                        handleChange={props.handleChange as ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>}
                        questionInfo={questionInfo}
                        classes={props.classes}
                      />
                    </FormControl>
                  </Col>
                </Row>
              ))}
              <Row around="xs" className="small-top-margin row-reverse">
                <Col>
                  <input
                    data-cy="overseasvalidationsubmitbutton"
                    type="submit"
                    value="Next"
                    id="overseasvalidationsubmitbutton"
                    className="primary-button"
                  />
                </Col>
                <Col>
                  {props?.history?.goBack && props.cancelHandler ? (
                    <>
                      <button type="button" className="secondary-button" onClick={props.cancelHandler}>
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="secondary-button small-left-margin"
                        data-cy="backButton"
                        onClick={props.history.goBack}
                      >
                        Back
                      </button>
                    </>
                  ) : (
                    <Link data-cy="cancel-button" className="secondary-button" to="/login">
                      Cancel
                    </Link>
                  )}
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default withStyles(styles, { withTheme: true })(OverseasValidationForm);
