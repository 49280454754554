import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { BaseMyProfileState } from './EditContactInfo';

export const validationStateSchema = {
  languagePreference: {
    required: false,
    validator: null,
  },
  receiveNoMail: {
    required: false,
    validator: null,
  },
  receiveNoCalls: {
    required: false,
    validator: null,
  },
  receiveTab: {
    required: false,
    validator: null,
  },
};

const EditContactPreferences = (props: BaseMyProfileState) => {
  const details = props.details;
  let languagePreferenceOptionsList;

  if (details.languagePreferenceOptions.value !== undefined)
    languagePreferenceOptionsList = details.languagePreferenceOptions.value.map((item: string, key: string) => (
      <MenuItem key={key} value={item}>
        {item}
      </MenuItem>
    ));

  return (
    <div>
      <h4 className="medium-left-padding small-top-padding">Contact Preferences</h4>
      <p className="medium-left-padding small-top-padding">
        {' '}
        * Some communications from the National Spiritual Assembly may be available in Spanish.
      </p>
      <div className="medium-left-padding">
        <Row>
          <p className="medium-left-padding inline-vertical-center">Language Preference &nbsp; </p>
          <Col sm={6}>
            <FormControl data-cy="form">
              <Select
                required
                value={details.languagePreference.value ?? ''}
                onChange={props.handleChange}
                data-cy="inputlanguagePreference"
                inputProps={{ name: 'languagePreference' }}
                variant="outlined"
                margin="dense"
              >
                {languagePreferenceOptionsList}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="large-top-padding">
          <Col sm={4}>
            <label aria-label="receiveNoMail">
              <input
                id="receiveNoMail"
                name="receiveNoMail"
                type="checkbox"
                data-cy="receiveNoMail"
                checked={details.receiveNoMail.value ?? ''}
                onChange={props.handleChange}
              ></input>{' '}
              I Request No Mail by Post
            </label>
          </Col>
          <Col sm={4}>
            <label aria-label="receiveNoCalls">
              <input
                id="receiveNoCalls"
                name="receiveNoCalls"
                type="checkbox"
                data-cy="receiveNoCalls"
                checked={details.receiveNoCalls.value ?? ''}
                onChange={props.handleChange}
              ></input>{' '}
              I Request No Phone Calls
            </label>
          </Col>
          <Col sm={4}>
            <label aria-label="receiveTab">
              <input
                id="receiveTab"
                name="receiveTab"
                type="checkbox"
                data-cy="receiveTab"
                checked={details.receiveTab.value ?? ''}
                onChange={props.handleChange}
              ></input>{' '}
              I Would Like To Receive The American Bahá’í Magazine
            </label>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditContactPreferences;
